import { useState, useEffect, useRef } from "react"
import * as masks from "js-base-utils"

export default function Input({ register, el, errors, formCallback = false }) {

    let [error, setError] = useState({ status: false, messages: [] });
    let [forceType, setForceType] = useState(null);


    const onChangeCallback = (e) => {
        e.preventDefault();
        var name = e.target.name;
        var value = e.target.value;
        if (el.mask === "cpfcnpj") {
            e.target.value = masks["cpfCnpjMask"](value);
        } else if (el.mask === "cellphone") {
            e.target.value = masks["cellphoneMask"](value);
        }
        if (formCallback) {
            var obj = {}
            obj[name] = value
            formCallback(obj)
        }
    }

    const showPassword = () => {
        if (forceType) {
            setForceType(null)
        } else {
            setForceType("text")
        }
    }

    if (el.hidden) {
        return (
            <input
                hidden={el.hidden}
                type={el.type}
                {...register(el.name)}
                defaultValue={el.defaultValue}
                id={el.name}
                className={"hidden"}
            />
        )
    }

    return (
        <div className={el.width}>
            <div className="relative">
                <label for={el.name} className="p-1 text-gray-900">
                    {el.label}
                </label>
                {
                    (["textarea"].includes(el.type)) &&
                    <textarea
                        autocomplete={el.autocomplete || "on"}
                        inputMode={el.inputMode || "text"} //"numeric", decimal, tel, email
                        disabled={el.disabled || false}
                        hidden={el.hidden || false}
                        maxLength={el.maxLength || false}
                        type={el.type}
                        {...register(el.name)}
                        id={el.name}
                        placeholder={el.placeholder || el.label}
                        onInput={(e) => onChangeCallback(e)}
                        className={`rounded-lg border-transparent h-36 flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base ${error.status ? "ring-2 ring-red-600 border-transparent outline-none" : "focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"}`}
                    />
                }
                {
                    (["select"].includes(el.type)) &&
                    <select
                        autocomplete={el.autocomplete || "on"}
                        inputMode={el.inputMode || "text"} //"numeric", decimal, tel, email
                        disabled={el.disabled || false}
                        hidden={el.hidden || false}
                        maxLength={el.maxLength || false}
                        type={el.type}
                        {...register(el.name)}
                        defaultValue={el.defaultValue || false}
                        id={el.name}
                        placeholder={el.placeholder || el.label}
                        onInput={(e) => onChangeCallback(e)}
                        className={`flex-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm ${error.status ? "ring-2 ring-red-600 border-transparent outline-none" : "focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"}`}
                    >
                        {el.options.map((option, key) =>
                            <option value={option.value} key={key}>
                                {option.label}
                            </option>
                        )}
                    </select>
                }
                {
                    (!["textarea", "select"].includes(el.type)) &&
                    <>
                        <input
                            autocomplete={el.autocomplete || "on"}
                            inputMode={el.inputMode || "text"} //"numeric", decimal, tel, email
                            pattern={el.pattern || false}
                            disabled={el.disabled || false}
                            hidden={el.hidden || false}
                            maxLength={el.maxLength || false}
                            type={forceType ? forceType : el.type}
                            {...register(el.name)}
                            id={el.name}
                            placeholder={el.placeholder || el.label}
                            onInput={(e) => onChangeCallback(e)}
                            className={`rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base ${error.status ? "ring-2 ring-red-600 border-transparent outline-none" : "focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"}`}
                        />
                        {["password"].includes(el.type) &&
                            <span 
                                className="absolute text-sm font-bold text-blue-700 hover:bg-blue-100 px-2 rounded-full leading-normal cursor-pointer my-2.5 mr-1 right-0"
                                onClick={showPassword}
                            >
                                ver
                            </span>
                        }
                    </>
                }
                <p className="h-4 text-red-500 text-sm pl-1 pt-1 mb-1">
                    {errors[el.name]?.message}
                </p>
            </div>
        </div>
    )
}