import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { XCircleIcon, LightningBoltIcon } from "@heroicons/react/solid";
import { deleteLocalItem, updateItem } from "../../../lib/actions/rooms";
// import AddLocalImageToItem from "./AddLocalImageToItem";
import LocalParagraphForm from "./LocalParagraphForm";
import ToggleDone from "./ToggleDone";
import { items } from "./optionsData";
import LocalDataSelect from "./LocalDataSelect";

import ItemDescriptionModal from "./ItemDescriptionModal";

export default function App(props) {
  const [open, setOpen] = useState(false);
  const [forceValue, setForceValue] = useState(null);

  const onChange = async (obj) => {
    if (props.el.local_id) {
      await updateItem(props.el.local_id, obj);
    }
    return false;
  };

  const toggleDescriptionModal = async () => {
    var y = document.getElementById("docBody");
    if (open) {
      y?.classList?.remove("overflow-hidden");
      setOpen(false);
    } else {
      y?.classList?.add("overflow-hidden");
      setOpen(true);
    }
  };

  const callbackDescription = async (obj) => {
    await onChange(obj);
    setForceValue(obj.description);
  };

  const removeForceValue = () => {
    setForceValue(null);
  };

  return (
    <div className="bg-white shadow-lg rounded-lg w-11/12 mx-auto my-8 p-4">
      <div className="flex w-full justify-between mb-4">
        <div className="w-5/6 flex flex-row justify-start">
          <div className="w-1/6 flex justify-items-start  content-start">
            <ToggleDone
              value={props.el.done}
              callback={onChange}
              callback_key_name={"done"}
            />
          </div>
          <div className="w-5/6 flex justify-items-center  content-start">
            <LocalDataSelect
              initialValue={props.el.name}
              placeholder="Item"
              name="name"
              callback={onChange}
              options={items}
            />
          </div>
        </div>
        <button
          type="button"
          onClick={() =>
            deleteLocalItem(props.el.local_id, props.inspection_id)
          }
          className=" my-1 flex justify-end items-start focus:ring-red-500 focus:ring-offset-red-200 transition ease-in duration-200 text-base font-semibold focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg"
        >
          <XCircleIcon className="h-8 w-8 text-red-600" />
        </button>
      </div>
      <div className="relative">
        <LocalParagraphForm
          initialValue={props.el.description}
          forceValue={forceValue}
          removeForceValue={removeForceValue}
          placeholder="Descrição Entrada"
          name="description"
          callback={onChange}
          type="Entrada"
        />
        <button
          type="button"
          class="py-2 px-2 w-8 h-8 flex justify-center items-center  bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg "
        >
          <LightningBoltIcon
            className="text-white"
            onClick={toggleDescriptionModal}
          />
        </button>
      </div>
      {props.inspection.current_inspection_type.includes("Saída") && (
        <div className="relative">
          <LocalParagraphForm
            initialValue={props.el.status}
            placeholder="Descrição Saída"
            name="status"
            callback={onChange}
            type="Saída"
          />
        </div>
      )}
      {open && (
        <ItemDescriptionModal
          {...props}
          toggleDescriptionModal={toggleDescriptionModal}
          callbackDescription={callbackDescription}
          name="description"
        />
      )}

      {/* <AddLocalImageToItem 
        props={{...props, local_room_id: props.el.local_room_id, local_item_id: props.el.local_id, figure_type: "inspection_in"}}
      /> */}

      {/* {JSON.stringify(props, null, 2)} */}
    </div>
  );
}
