import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
// router
import { isBrowser } from 'react-device-detect';
import { BrowserRouter } from "react-router-dom";
// Redux
import { Provider } from "react-redux";
// Redux Persist
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./store/index";
// PWA
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
// Metrics
import reportWebVitals from "./reportWebVitals";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { TouchBackend } from 'react-dnd-touch-backend'

// Assets
import "./index.css";
import "react-toastify/dist/ReactToastify.css";

const app = (
  <DndProvider backend={isBrowser ? HTML5Backend : TouchBackend}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </DndProvider>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
