import ShowInspection from "../components/Inspections/ShowInspection"
import HeaderMetadata from "../components/HeaderMetadata/HeaderMetadata"
export default function App(props) {
    return (
        <>
            <HeaderMetadata
                title="Vistoria"
                description="Vistoria"
            />
            <ShowInspection
                match={props.match}
            />
        </>
    );
}