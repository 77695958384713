import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import rootReducer from "./reducers"
import thunk from 'redux-thunk';

// const composeEnhancers = compose;
const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;

// EXPORT STORE - WITH PERSIST
const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['ui', 'auth',],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export let store = createStore(persistedReducer, compose(
  applyMiddleware(thunk)
))
export let persistor = persistStore(store)