// Parede, colocar parte de azelejos propria pois sempre tem




export function resolveDescription(obj) {
  var description = "";
  description = simpleConcat(description, obj["Tipo"], "");
  description = simpleConcat(description, obj["Material"], " ");

  description = simpleConcat(description, obj["Vaso Cor"]);

  description = simpleConcat(description, obj["Estado Geral"], " em estado ");
  if (obj["Pintura"] && obj["Pintura"].length > 0) {
    description = `${description}, ${
      renderArrayOfValues(obj["Pintura"]).includes("com")
        ? `${renderArrayOfValues(
            obj["Pintura"]
          )} em estado ${renderArrayOfValues(obj["Estado Pintura"])}`
        : `${renderArrayOfValues(obj["Pintura"])}`
    }`;
  }
  if (obj["Acabamentos"] && obj["Acabamentos"].length > 0) {
    description = `${description}, ${renderArrayOfValues(
      obj["Acabamentos"]
    )} em estado ${renderArrayOfValues(obj["Estado Geral"])}`;
  }
  description = simpleConcat(description, obj["Trinco"]);
  description = simpleConcat(description, obj["Fechadura"]);
  description = simpleConcat(description, obj["Maçaneta"]);
  description = simpleConcat(description, obj["Chaves"]);
  description = simpleConcat(description, obj["Vidros"]);
  description = simpleConcat(description, obj["Furos"]);
  description = simpleConcat(description, obj["Infiltração"]);
  description = simpleConcat(description, obj["Complemento"], ", (", ")");

  description = simpleConcat(description, obj["Gavetas"]);
  description = simpleConcat(description, obj["Gavetas Complemento"], " ");
  description = simpleConcat(description, obj["Gavetas Estado"], " ");

  description = simpleConcat(description, obj["Portas"]);
  description = simpleConcat(description, obj["Portas Complemento"], " ");
  description = simpleConcat(description, obj["Portas Estado"], " ");

  description = simpleConcat(description, obj["Pés de Apoio"]);
  description = simpleConcat(description, obj["Pés de Apoio Complemento"], " ");
  description = simpleConcat(description, obj["Pés de Apoio Estado"], " ");

  description = simpleConcat(description, obj["Espelho"]);
  description = simpleConcat(description, obj["Espelho Complemento"], " ");
  description = simpleConcat(description, obj["Espelho Estado"], " ");

  description = simpleConcat(description, obj["Registro"]);
  description = simpleConcat(description, obj["Registro Complemento"], " ");
  description = simpleConcat(description, obj["Registro Estado"], " ");

  description = simpleConcat(description, obj["Torneira"]);
  description = simpleConcat(description, obj["Torneira Complemento"], " ");
  description = simpleConcat(description, obj["Torneira Estado"], " ");

  description = simpleConcat(description, obj["Sifão"]);
  description = simpleConcat(description, obj["Sifão Complemento"], " ");
  description = simpleConcat(description, obj["Sifão Estado"], " ");

  description = simpleConcat(description, obj["Ralo"]);
  description = simpleConcat(description, obj["Ralo Complemento"], " ");
  description = simpleConcat(description, obj["Ralo Estado"], " ");

  description = simpleConcat(description, obj["Grelha"]);
  description = simpleConcat(description, obj["Grelha Complemento"], " ");
  description = simpleConcat(description, obj["Grelha Estado"], " ");

  description = simpleConcat(description, obj["Assento"]);
  description = simpleConcat(description, obj["Assento Complemento"], " ");
  description = simpleConcat(description, obj["Assento Estado"], " ");

  description = simpleConcat(description, obj["Descarga"]);
  description = simpleConcat(description, obj["Descarga Complemento"], " ");
  description = simpleConcat(description, obj["Descarga Estado"], " ");

  description = simpleConcat(description, obj["Box Estutura"]);
  description = simpleConcat(description, obj["Box Estutura Complemento"], " ");
  description = simpleConcat(description, obj["Box Estutura Estado"], " ");

  description = simpleConcat(description, obj["Interruptores"]);
  description = simpleConcat(description, obj["Tomadas"]);
  description = simpleConcat(description, obj["Lâmpadas"]);
  description = simpleConcat(description, obj["Disjustores"]);
  description = simpleConcat(description, obj["Cabos"]);

  return description;
}

// CADEADO
// SEPARAR STATUS DA PINTURA

export function optionsResolver(item_name) {
  item_name = item_name.toLowerCase();
  if (["teto", "telhado", "cobertura"].includes(item_name)) {
    return teto_options;
  }
  if (
    ["porta", "portão", "batente", "portal", "janela", "vitrô"].includes(
      item_name
    )
  ) {
    return porta_options;
  }
  if (["paredes", "muro", "mureta", "beiral", "parede"].includes(item_name)) {
    return paredes_options;
  }
  if (["piso", "calçada", "calçadinha", "cimentado"].includes(item_name)) {
    return piso_options;
  }
  if (["elétrica", "quadro de distribuição", "interfone"].includes(item_name)) {
    return eletrica_options;
  }
  if (["pia", "balcão", "tanque"].includes(item_name)) {
    return pia_options;
  }
  if (["hidráulica", "cavalete"].includes(item_name)) {
    return hidraulica_options;
  }
  if (["acessórios"].includes(item_name)) {
    return acessorios_options;
  }
  if (["espelho"].includes(item_name)) {
    return espelho_options;
  }
  if (
    [
      "armário de parede",
      "gabinete",
      "armário",
      "guarda-roupa",
      "prateleiras",
    ].includes(item_name)
  ) {
    return armario_options;
  }
  if (["vaso sanitário", "bidê"].includes(item_name)) {
    return toilet_options;
  }
  if (["box"].includes(item_name)) {
    return box_options;
  }
  if (["chuveiro"].includes(item_name)) {
    return chuveiro_options;
  }

  return default_options;
}

// Options for each item

const porta_options = [
  {
    title: "Tipo",
    type: "radio",
    options: [
      "de abrir",
      "de correr",
      "sanfonada",
      "basculante",
      "veneziana",
      "camarão",
      "apenas portal",
    ],
  },
  {
    title: "Material",
    type: "radio",
    options: [
      "de madeira",
      "de metal",
      "de blindex",
      "de vidro jateado",
      "de vidro comum",
      "de vidro fumê",
      "de PVC",
    ],
  },
  {
    title: "Estado Geral",
    type: "radio",
    options: ["novo", "ótimo", "bom", "médio"],
  },
  {
    title: "Pintura",
    type: "radio",
    options: ["com pintura", "envernizado", "sem pintura"],
  },
  {
    title: "Estado Pintura",
    type: "radio",
    options: ["novo", "ótimo", "bom", "médio"],
  },
  {
    title: "Acabamentos",
    type: "select",
    options: ["com portal", "com guarnição"],
  },
  {
    title: "Trinco",
    type: "radio",
    options: ["com trinco funcionando", "com trinco sem funcionar"],
  },
  {
    title: "Fechadura",
    type: "radio",
    options: [
      "com fechadura funcionando",
      "com fechadura sem funcionar",
      "sem fechadura",
    ],
  },
  {
    title: "Maçaneta",
    type: "radio",
    options: [
      "com maçaneta funcionando",
      "com maçaneta sem funcionar",
      "sem maçaneta",
      "com puxador funcionando",
      "com puxador sem funcionar",
      "sem puxador",
    ],
  },
  {
    title: "Chaves",
    type: "radio",
    options: [
      "com chave acoplada funcionando",
      "com chave acoplada sem funcionar",
      "com chave funcionando",
      "com chave sem funcionar",
      "sem chave",
    ],
  },
  {
    title: "Vidros",
    type: "radio",
    options: [
      "com vidros íntegros",
      "com vidros com trincado",
      "com vidros com dano",
    ],
  },
  {
    title: "Complemento",
    type: "select",
    options: [
      "sem lascas",
      "sem furos",
      "sem respingos",
      "sem infiltrações",
      "sem ferrugem",
      "sem descascados",
      "sem pregos",
    ],
  },
];

const paredes_options = [
  {
    title: "Material",
    type: "radio",
    options: [
      "alvenaria",
      "tijolinho à vista",
      "alvenaria com barrado de azulejo",
      "azulejo até o teto",
      "chapiscado",
      "concreto",
    ],
  },
  {
    title: "Estado Geral",
    type: "radio",
    options: ["novo", "ótimo", "bom", "médio"],
  },
  {
    title: "Pintura",
    type: "radio",
    options: ["com pintura", "sem pintura"],
  },
  {
    title: "Estado Pintura",
    type: "radio",
    options: ["novo", "ótimo", "bom", "médio"],
  },
  {
    title: "Acabamentos",
    type: "select",
    options: [
      "com barrado de tinta óleo",
      "com papel de parede",
      "com textura",
    ],
  },
  {
    title: "Furos",
    type: "radio",
    options: ["sem furos", "com furos"],
  },
  {
    title: "Infiltração",
    type: "radio",
    options: [
      "sem infiltração",
      "com infiltração leve",
      "com infiltração moderada",
      "com infiltração aparente",
    ],
  },
  {
    title: "Complemento",
    type: "select",
    options: ["sem trincas", "sem manchas", "sem marcas"],
  },
];

const teto_options = [
  {
    title: "Tipo",
    type: "radio",
    options: [
      "sem forro",
      "forro",
      "telhas",
      "beiral de madeira",
      "beiral de laje",
    ],
  },
  {
    title: "Material",
    type: "radio",
    options: [
      "laje",
      "cartonado de gesso",
      "PVC",
      "madeirite",
      "madeira",
      "barro",
      "brasilit",
      "calhetão",
      "metálica simples",
      "metálica sanduíche",
    ],
  },
  {
    title: "Estado Geral",
    type: "radio",
    options: ["novo", "ótimo", "bom", "médio"],
  },
  {
    title: "Pintura",
    type: "radio",
    options: ["com pintura", "sem pintura", "envernizado"],
  },
  {
    title: "Estado Pintura",
    type: "radio",
    options: ["novo", "ótimo", "bom", "médio"],
  },
  {
    title: "Acabamentos",
    type: "select",
    options: [
      "com acabamento de gesso",
      "com acabamento em PVC",
      "com beiral de madeira",
      "com calha de metal",
      "com rufo de metal",
    ],
  },
  {
    title: "Complemento",
    type: "select",
    options: ["sem infiltração", "sem trincas", "sem manchas", "sem marcas"],
  },
];

const piso_options = [
  {
    title: "Material",
    type: "radio",
    options: [
      "frio",
      "antiderrapante",
      "ardósia",
      "procelanato",
      "granito",
      "pedras",
      "taco de madeira",
      "laminado de madeira",
      "carpete",
      "carpete de madeira",
      "concreto",
      "cimento queimado",
      "vermelhão",
      "terra",
      "cacos de piso",
    ],
  },
  {
    title: "Estado Geral",
    type: "radio",
    options: ["novo", "ótimo", "bom", "médio"],
  },
  {
    title: "Pintura",
    type: "radio",
    options: ["com pintura", "sem pintura"],
  },
  {
    title: "Estado Pintura",
    type: "radio",
    options: ["novo", "ótimo", "bom", "médio"],
  },
  {
    title: "Acabamentos",
    type: "select",
    options: ["com rodapé", "com rejunte sem falhas"],
  },
  {
    title: "Infiltração",
    type: "radio",
    options: [
      "sem infiltração",
      "com infiltração leve",
      "com infiltração moderada",
      "com infiltração aparente",
    ],
  },
  {
    title: "Complemento",
    type: "select",
    options: [
      "sem trincas",
      "sem manchas",
      "sem marcas",
      "sem partes quebradas",
    ],
  },
];

const eletrica_options = [
  {
    title: "Interruptores",
    type: "select",
    counter: true,
    status: ["", "110v", "220v", "funcionando"],
    options: [
      "interruptor 01 tecla",
      "interruptor 02 teclas",
      "interruptor 03 teclas",
      "interruptor 01  tecla e 01 tomada",
      "interruptor 01  tecla e 02 tomada",
      "interruptor 02  teclas e 01 tomada",
      "interruptor 03  teclas controle de ventilador",
      "espelho cego",
      "espelho com 01 furo",
    ],
  },
  {
    title: "Tomadas",
    type: "select",
    counter: true,
    status: ["110v", "220v", "não funciona"],
    options: ["tomada simples", "tomada dupla", "tomada tripla"],
  },
  {
    title: "Lâmpadas",
    type: "select",
    counter: true,
    status: ["funcionando", "não funciona", "sem lâmpada"],
    options: [
      "lâmpada halogênica",
      "lâmpada de LED",
      "lâmpada tubolar de LED",
      "lâmpada tubolar de fluorescente",
      "lâmpada dicroica",
      "spot",
      "spot duplo",
      "bocal",
      "arandela",
      "plafón quadrado",
      "plafón redondo",
      "luminária",
    ],
  },
  {
    title: "Disjustores",
    type: "select",
    counter: true,
    options: [
      "quadro de disjuntor",
      "disjuntor monofásico",
      "disjuntor bifásico",
    ],
  },
  {
    title: "Cabos",
    type: "select",
    counter: true,
    options: ["cabo de TV", "cabo de rede", "cabo fibra ótica"],
  },
];

const acessorios_options = [
  {
    title: "Material",
    type: "select",
    counter: false,
    status: true,
    options: [
      "cabide gancho",
      "cabide gancho duplo",
      "cabide argola",
      "cabide barra",
      "papeleiro sem tampa",
      "papeleiro com tampa",
      "saboneteira",
      "shampoozeira",
    ],
  },
];

const pia_options = [
  {
    title: "Material",
    type: "radio",
    options: [
      "inox",
      "granito",
      "coluna de louça",
      "louça",
      "porcelanato",
      "mármore",
      "granilit",
      "concreto",
      "PVC",
    ],
  },
  {
    title: "Estado Geral",
    type: "radio",
    options: ["novo", "ótimo", "bom", "médio"],
  },
  {
    title: "Acabamentos",
    type: "select",
    options: [
      "com bordas retas",
      "com bordas boleadas",
      "com base de alvenaria",
    ],
  },
  {
    title: "Pintura",
    type: "radio",
    options: ["com pintura", "sem pintura"],
  },
  {
    title: "Estado Pintura",
    type: "radio",
    options: ["novo", "ótimo", "bom", "médio"],
  },
  {
    title: "Complemento",
    type: "select",
    options: [
      "bem fixada",
      "sem trincas",
      "sem marcas",
      "sem manchas",
      "sem ferrugem",
    ],
  },
  {
    title: "Torneira",
    type: "radio",
    options: [
      "torneira cromada simples",
      "torneira cromada premium",
      "torneira metal amarelo simples",
    ],
  },
  {
    title: "Torneira Complemento",
    type: "select",
    options: [
      "com bico",
      "com 01 registro",
      "com 02 registros",
      "com conector para mangueira",
      "com conector para máquina/tanquinho",
    ],
  },
  {
    title: "Torneira Estado",
    type: "radio",
    options: ["novo", "ótimo", "bom", "médio"],
  },
  {
    title: "Sifão",
    type: "radio",
    options: [
      "sifão simples de PVC branco",
      "sifão duplo de PVC branco",
      "sifão simples de PVC cromado",
      "sifão duplo de PVC cromado",
      "sifão de metal",
    ],
  },
  {
    title: "Sifão Complemento",
    type: "select",
    options: ["bem fixado", "sem vazamento"],
  },
  {
    title: "Sifão Estado",
    type: "radio",
    options: ["novo", "ótimo", "bom", "médio"],
  },
];

const espelho_options = [
  {
    title: "Espelho",
    type: "radio",
    options: ["espelho simples sem detalhes", "espelho com detalhes"],
  },
  {
    title: "Espelho Complemento",
    type: "select",
    options: [
      "com moldura",
      "bem fixado",
      "sem marcas",
      "sem manchas",
      "sem corrosão",
      "sem ferrugem",
    ],
  },
  {
    title: "Espelho Estado",
    type: "radio",
    options: ["novo", "ótimo", "bom", "médio"],
  },
];

const armario_options = [
  {
    title: "Tipo",
    type: "radio",
    options: ["planejado sob medida"],
  },
  {
    title: "Material",
    type: "radio",
    options: ["de MDF", "de madeira", "de metal", "de blindex", "de plástico"],
  },
  {
    title: "Estado Geral",
    type: "radio",
    options: ["novo", "ótimo", "bom", "médio"],
  },
  {
    title: "Pintura",
    type: "radio",
    options: ["com pintura", "com verniz", "sem pintura"],
  },
  {
    title: "Estado Pintura",
    type: "radio",
    options: ["novo", "ótimo", "bom", "médio"],
  },
  {
    title: "Gavetas",
    type: "radio",
    options: ["com gaveta"],
  },
  {
    title: "Gavetas Complemento",
    type: "select",
    options: ["com puxador bem fixado"],
  },
  {
    title: "Gavetas Estado",
    type: "radio",
    options: ["novo", "ótimo", "bom", "médio"],
  },
  {
    title: "Portas",
    type: "radio",
    options: ["com porta"],
  },
  {
    title: "Portas Complemento",
    type: "select",
    options: [
      "com puxador bem fixado",
      "de placa de acrílico",
      "de vidro",
      "de blindex",
    ],
  },
  {
    title: "Portas Estado",
    type: "radio",
    options: ["novo", "ótimo", "bom", "médio"],
  },
  {
    title: "Pés de Apoio",
    type: "radio",
    options: ["com pés"],
  },
  {
    title: "Pés de Apoio Complemento",
    type: "select",
    options: ["bem fixados"],
  },
  {
    title: "Pés de Apoio Estado",
    type: "radio",
    options: ["novo", "ótimo", "bom", "médio"],
  },
  {
    title: "Espelho",
    type: "radio",
    options: ["espelho simples sem detalhes", "espelho com detalhes"],
  },
  {
    title: "Espelho Complemento",
    type: "select",
    options: [
      "com moldura",
      "bem fixado",
      "sem marcas",
      "sem manchas",
      "sem corrosão",
      "sem ferrugem",
    ],
  },
  {
    title: "Espelho Estado",
    type: "radio",
    options: ["novo", "ótimo", "bom", "médio"],
  },
  {
    title: "Complemento",
    type: "select",
    options: [
      "sem lascas",
      "sem furos",
      "sem respingos",
      "sem infiltrações",
      "sem ferrugem",
      "sem descascados",
      "sem pregos",
    ],
  },
];

const hidraulica_options = [
  {
    title: "Torneira",
    type: "radio",
    options: [
      "torneira cromada simples",
      "torneira cromada premium",
      "torneira metal amarelo simples",
    ],
  },
  {
    title: "Torneira Complemento",
    type: "select",
    options: [
      "com bico",
      "com cavalete",
      "com 01 registro",
      "com 02 registros",
      "com conector para mangueira",
      "com conector para máquina/tanquinho",
    ],
  },
  {
    title: "Torneira Estado",
    type: "radio",
    options: ["novo", "ótimo", "bom", "médio"],
  },
  {
    title: "Registro",
    type: "radio",
    options: [
      "registro cromado simples",
      "registro cromado premium",
      "registro metal amarelo simples",
    ],
  },
  {
    title: "Registro Complemento",
    type: "select",
    options: ["bem fixado", "sem vazamento", "funcionando"],
  },
  {
    title: "Registro Estado",
    type: "radio",
    options: ["novo", "ótimo", "bom", "médio"],
  },
  {
    title: "Ralo",
    type: "radio",
    options: ["ralo cromado simples", "ralo plástico"],
  },
  {
    title: "Ralo Complemento",
    type: "select",
    options: ["bem fixado", "sem vazamento", "com tampa", "funcionando"],
  },
  {
    title: "Ralo Estado",
    type: "radio",
    options: ["novo", "ótimo", "bom", "médio"],
  },
  {
    title: "Grelha",
    type: "radio",
    options: ["grelha cromado simples", "grelha de metal"],
  },
  {
    title: "Grelha Complemento",
    type: "select",
    options: ["bem fixado", "sem vazamento", "com tampa", "funcionando"],
  },
  {
    title: "Grelha Estado",
    type: "radio",
    options: ["novo", "ótimo", "bom", "médio"],
  },
];

const toilet_options = [
  {
    title: "Material",
    type: "radio",
    options: ["louça"],
  },
  {
    title: "Vaso Cor",
    type: "radio",
    options: ["branco", "preto", "marrom", "cinza", "creme"],
  },
  {
    title: "Estado Geral",
    type: "radio",
    options: ["novo", "ótimo", "bom", "médio"],
  },
  {
    title: "Complemento",
    type: "select",
    options: ["bem fixado", "bem rejuntado", "sem manchas", "sem marcas"],
  },
  {
    title: "Assento",
    type: "radio",
    options: ["com assento rígido", "com assento almofadado", "sem assento"],
  },
  {
    title: "Assento Complemento",
    type: "radio",
    options: ["com tampa", "sem tampa"],
  },
  {
    title: "Assento Estado",
    type: "select",
    options: [
      "novo",
      "em estado ótimo",
      "em estado bom",
      "sem marcas",
      "sem manchas",
      "sem bolor",
      "bem fixado",
    ],
  },
  {
    title: "Descarga",
    type: "radio",
    options: [
      "com caixa de descarga acoplada",
      "com caixa de descarga suspensa",
      "com válvula de descarga de parede",
    ],
  },
  {
    title: "Descarga Complemento",
    type: "radio",
    options: [
      "com botão válvula em perfeito estado",
      "com botão válvula quebrada",
      "com cordinha com acabamento",
      "com cordinha sem acabamento",
    ],
  },
  {
    title: "Descarga Estado",
    type: "select",
    options: ["sem vazamento", "bem fixado", "com vazamento"],
  },
];

const box_options = [
  {
    title: "Material",
    type: "radio",
    options: [
      "sem box, apenas varão para cortina",
      "de blindex",
      "de acrílico",
    ],
  },
  {
    title: "Estado Geral",
    type: "radio",
    options: ["novo", "ótimo", "bom", "médio"],
  },
  {
    title: "Complemento",
    type: "select",
    options: [
      "sem trincas",
      "sem manchas",
      "sem marcas",
      "sem partes quebradas",
      "bem fixado",
    ],
  },
  {
    title: "Box Estutura",
    type: "radio",
    options: ["estrutura de alumínio"],
  },
  {
    title: "Box Estutura Complemento",
    type: "select",
    options: [
      "com triho",
      "com vedação",
      "com puxador interno",
      "com puxador externo",
    ],
  },
  {
    title: "Box Estutura Estado",
    type: "select",
    options: [
      "novo",
      "em estado ótimo",
      "em estado bom",
      "sem marcas",
      "sem manchas",
      "sem bolor",
      "bem fixado",
    ],
  },
];

const chuveiro_options = [
  {
    title: "Tipo",
    type: "radio",
    options: [
      "apenas furo para instação",
      "apenas cano para chuveiro",
      "simples de plástico", 
      "grande de plástico", 
      "grande de inox",
      "ducha de inox",
    ],
  },
  {
    title: "Funcionamento",
    type: "radio",
    options: [
      "não testado",
      "ccom 3 temperaturas funcionando",
      "com seletor variável de temperatura funcionando", 
      "sem seletor de temperatura", 
      "sem seletor de temperatura com aquecimento a gás", 
    ],
  },
  {
    title: "Fiação",
    type: "radio",
    options: [
      "apenas saída para instalação elétrica",
      "com fiação não instalada",
      "com fiação instalada com fita isolante", 
      "com fiação instalada com conectores", 
    ],
  },
  {
    title: "Estado Geral",
    type: "radio",
    options: ["novo", "ótimo", "bom", "médio"],
  },
  {
    title: "Registro",
    type: "radio",
    options: [
      "com registro cromado",
      "com registro de plástico",
      "com registro duplo cromado",
      "com registro duplo de plástico",
    ],
  },
  {
    title: "Estado do Registro",
    type: "radio",
    options: [
      "funcionando, bem fixado, sem vazamento",
      "funcionando, bem fixado, com vazamento",
    ],
  },
];

const default_options = [
  {
    title: "Estado Geral",
    type: "radio",
    options: ["novo", "ótimo", "bom", "médio"],
  },
  {
    title: "Pintura",
    type: "radio",
    options: ["com pintura", "sem pintura"],
  },
];

// Utilities
function renderArrayOfValues(arr) {
  if (arr) {
    if (arr.length > 1) {
      return arr.join(", ");
    } else {
      return arr[0];
    }
  } else {
    return "";
  }
}

function simpleConcat(text, value, separator = ", ", final_character = "") {
  if (renderArrayOfValues(value)) {
    if (text.length > 0) {
      return `${text}${separator}${renderArrayOfValues(value)}${final_character}`;
    } else {
      return `${renderArrayOfValues(value)}${final_character}`;
    }
  } else {
    return text;
  }
}

// const options = {
//   teto: {
//     materials: {
//       type: "radio",
//       options: [
//         "sem forro",
//         "forro laje",
//         "forro cartonado de gesso",
//         "forro de PVC",
//         "forro madeirite",
//       ],
//     },
//     status: {
//       type: "radio",
//       options: ["novo", "ótimo", "bom", "médio", "precário"],
//     },
//     paint: {
//       type: "radio",
//       options: ["com pintura nova", "com pintura boa", "com pintura média"],
//     },
//     others: {
//       type: "radio",
//       options: [
//         "com acabamento de gesso novo",
//         "com acabamento de gesso em ótimo estado",
//         "com acabamento de gesso em bom estado",
//       ],
//     },
//     complement: {
//       type: "radio",
//       options: ["(sem infiltracao, sem trincas, sem manchas e sem marcas)"],
//     },
//   },
// };
