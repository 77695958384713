import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { db } from "../../lib/db"
import { useLiveQuery } from "dexie-react-hooks";
import PageHeader from "../PagesNavigation/PageHeader"
import { loadingPage } from "../Placeholder/LoadingPage"
import errorPlaceholder from "../Placeholder/Error"
import { main_server, api_version } from "../../lib/api"
import { returnRequestData } from 'js-base-utils'

export default function App(props) {

    // Redux
    const dispatch = useDispatch();
    const auth_token = useSelector(state => state.auth.auth_token)

    // State
    const [room, setRoom] = useState({})
    const [data, setData] = useState({})
    const [error, setError] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const searchRoom = useLiveQuery(async () => {
        return await db.rooms.where("local_id").equals(parseInt(props?.match?.params?.local_room_id)).toArray()
    });
    useEffect(() => {
        if (searchRoom && searchRoom[0]) {
            setRoom(searchRoom[0])
        }
    }, [searchRoom])

    useEffect(() => {
        if (room.id) {
            downloadImages()
        }
    }, [room])
    // Effect


    const downloadImages = async () => {
        var requestResponse = await returnRequestData(
            `${main_server}${api_version}/figures`,
            "GET",
            { room_id: room.id, figure_type: [props.figure_type] },
            null,
            null,
            auth_token
        );
        var responseData = requestResponse.data;
        if (responseData.status >= 200 && responseData.status < 300) {
            setData(responseData)
        } else {
            setError(true)
        }
        setIsLoading(false)
    }



    if (isLoading) {
        return (
            <> {loadingPage()} </>
        )
    }

    if (error) {
        if (error?.response?.data?.errors[0] === "Faça o Login Novamente!") {
            // cache.clear();
            dispatch({ type: 'AUTH_ERROR', });
        }
        return (
            <> {errorPlaceholder(error)} </>
        )
    }

    return (
        <div className="min-h-screen bg-gray-100 pb-40">
            <PageHeader
                showBackButton
                title={`${room.room_name} - Fotos da Entrada`}
            />
            <div>
                {data.figures.sort((a, b) => { if (a.id > b.id) { return 1; } else { return -1 } }).map((el) => (
                    <div className="overflow-hidden bg-white shadow-lg rounded-lg w-11/12 md:w-1/2 lg:w-1/4 md:w-80 mx-auto my-8 p-2">
                        <div className="flex w-full justify-between">
                                <img className="object-contain h-auto w-full mx-auto" src={el.url}></img>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}