import React from 'react';
import { Helmet } from "react-helmet";

export default function App(props) {
    return (
        <Helmet>
            <title>{props.title}</title>
            {props.canonical_url &&
                <link rel="canonical" href={props.canonical_url} />}
            {props.description &&
                <meta name="description" content={props.description} />
            }
            {/* OG */}
            {props.og_title &&
                <meta property="og:title" content={props.title} />
            }
            {(props.og_description || props.description) &&
                <meta property="og:description" content={props.og_description || props.description} />
            }
        </Helmet>
    );
}