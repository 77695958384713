import { combineReducers } from 'redux';

// UI
const UI_INITIAL_STATE = {
    
}

function ui(state = UI_INITIAL_STATE, action) {
    switch (action.type) {
        default:
            return state;
    }
}


// Auth
const AUTH_INITIAL_STATE = {
    user_id: null,
    auth_token: null,
    is_authenticated: false,
}

function auth(state = AUTH_INITIAL_STATE, action) {
    switch (action.type) {
        case 'AUTH_SUCCESS':
            return { ...state, ...action.data };
        case 'AUTH_ERROR':
            return { ...AUTH_INITIAL_STATE };
        default:
            return state;
    }
}

export default combineReducers({
    ui,
    auth,
});