import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { db } from "../../lib/db"
import { useLiveQuery } from "dexie-react-hooks";
import ItemForm from "./components/ItemForm"
import { addLocalItem } from "../../lib/actions/rooms"
import { safeMaxOfArray } from "../../lib/actions/utilities"
import { PlusCircleIcon } from '@heroicons/react/solid'
import PageHeader from "../PagesNavigation/PageHeader"

export default function App(props) {

    // State
    const [room, setRoom] = useState({})
    const [inspection, setInspection] = useState({})
    const searchInspection = useLiveQuery(async () => {
        return await db.inspections.where("id").equals(parseInt(props?.match?.params?.id)).toArray()
    });
    const searchRoom = useLiveQuery(async () => {
        return await db.rooms.where("local_id").equals(parseInt(props?.match?.params?.local_room_id)).toArray()
    });
    const localItems = useLiveQuery(async () => {
        return await db.items.where("local_room_id").equals(parseInt(props?.match?.params?.local_room_id)).toArray()
    });
    useEffect(() => {
        if (searchRoom && searchRoom[0]) {
            setRoom(searchRoom[0])
        }
    }, [searchRoom])
    useEffect(() => {
        if (searchInspection && searchInspection[0]) {
            setInspection(searchInspection[0])
        }
    }, [searchInspection])

    const addItem = async () => {
        await addLocalItem({ name: "", inspection_id: parseInt(props?.match?.params?.id), local_room_id: parseInt(props?.match?.params?.local_room_id), position: safeMaxOfArray(localItems.map(a => a.position)) + 1 })
    }

    return (
        <div className="min-h-screen bg-gray-100 pb-40">
            <PageHeader
                showBackButton
                title={room.room_name}
            />
            <div className="h-1/2 w-full flex justify-around items-baseline px-3 py-5">
                <Link to={`${props?.match?.params?.local_room_id}/view_figures_in`}>
                    <button type="button" className="z-10 py-2 px-4 cursor-pointer bg-blue-600 hover:bg-blue-700 focus:ring-blue-900 focus:ring-offset-blue-900 transition ease-in duration-200 text-center text-white text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
                        Fotos da Entrada Salvas
                    </button>
                </Link>
                <Link to={`${props?.match?.params?.local_room_id}/figures_in`}>
                    <button type="button" className="z-10 py-2 px-4 cursor-pointer bg-green-600 hover:bg-green-700 focus:ring-green-900 focus:ring-offset-green-900 transition ease-in duration-200 text-center text-white text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
                        Adicionar Fotos de Entrada
                    </button>
                </Link>
                {inspection?.current_inspection_type?.includes("Saída") &&
                    <Link to={`${props?.match?.params?.local_room_id}/figures_out`}>
                        <button type="button" className="z-10 py-2 px-4 cursor-pointer bg-red-600 hover:bg-red-700 focus:ring-red-900 focus:ring-offset-red-900 transition ease-in duration-200 text-center text-white text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
                            Fotos da Saída
                        </button>
                    </Link>
                }
            </div>
            <div>
                {localItems && localItems.sort((a, b) => { if (a.position > b.position) { return 1; } else { return -1 } }).map((el) => (
                    <ItemForm
                        key={el.local_id}
                        el={el}
                        inspection_id={props?.match?.params?.id}
                        inspection={inspection}
                    />
                ))}
                <button type="button" onClick={addItem} className="w-11/12 sm:w-1/2 md:w-1/4 my-4 mx-auto py-2 px-4 flex justify-center items-center bg-gray-500 hover:bg-gray-700 focus:ring-gray-500 focus:ring-offset-gray-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                    <PlusCircleIcon className="h-6 w-6 text-white" />
                </button>
            </div>
        </div>
    );
}