import React, { useState, useEffect } from 'react';

export default function App(props) {
    const [value, setValue] = useState(props.value)

    const onChange = async () => {
        setValue(!value);
        if (props.callback) {
            var obj = {}
            obj[props.callback_key_name] = !value
            props.callback(obj)
        }
        return false
    }

    const ringColor = () => {
        var color = "bg-green-500 focus:ring-green-500 focus:ring-offset-green-200"
        if (!value) {
            color = "bg-red-500 focus:ring-red-500 focus:ring-offset-red-200"
        }
        return color
    }

    return (
        <button type="button" onClick={onChange} className="flex items-center mx-auto align-center">
            <span class={`rounded-xl relative p-2 ${ringColor()} transition ease-in duration-100 focus:outline-none focus:ring-2 focus:ring-offset-2`} />
        </button>
    );
}



