import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { createUrlFromBlob } from "../../../lib/actions/utilities"
import { XCircleIcon,  } from '@heroicons/react/solid'

const style = {
  cursor: "move",
};
export const Card = ({ id, el, index, moveCard, inspection_id, deleteLocalFigureFunction }) => {
  const ref = useRef(`card-${el.local_id}`);
  const [{ handlerId }, drop] = useDrop({
    accept: "card",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: "card",
    item: () => {
      return { id, index, base64: el.base64 };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <div className="w-1/4 float-left" ref={ref} style={{ ...style, opacity }} data-handler-id={handlerId}>
      <div key={el.local_id} className="relative text-center">
        <img className="" src={createUrlFromBlob(el.base64)} alt={"logo"}/>
        <button
          type="button"
          onClick={() =>
            deleteLocalFigureFunction(el.local_id, inspection_id)
          }
          className="absolute top-0 bg-white left-0 -mx-1 -my-1 flex justify-end items-start focus:ring-red-500 focus:ring-offset-red-200 transition ease-in duration-200 text-base font-semibold focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
        >
          <XCircleIcon className="h-5 w-5 text-red-600" />
        </button>
      </div>
    </div>
  );
};
