import IndexInspections from "../components/Inspections/IndexInspections"
import HeaderMetadata from "../components/HeaderMetadata/HeaderMetadata"
export default function App() {
    return (
        <>
            <HeaderMetadata
                title="Vistorias"
                description="Vistorias"
            />
            <IndexInspections />
        </>
    );
}