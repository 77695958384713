import React, { useState, useEffect } from "react";
// Router
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
// Redux
import { useSelector } from 'react-redux'
// Layouts
import AdminLayout from "./layouts/AdminLayout";
// Toast
import ToastContainer from "./lib/toasts";
// Containers
import Login from "./pages/Login"
import Inspections from "./pages/Inspections"
import Inspection from "./pages/Inspection"
import Room from "./pages/Room"
import RoomFiguresIn from "./pages/RoomFiguresIn"
import ViewFiguresIn from "./pages/ViewFiguresIn"
import RoomFiguresOut from "./pages/RoomFiguresOut"
// import { savePosition } from "./lib/actions/geolocation"

export default function App() {
  // Redux
  const auth = useSelector(state => state.auth)

  // State
  const [routes, setRoutes] = useState(<Switch></Switch>)

  const PUBLIC_ROUTES = (
    <Switch>
      <Route path='/:path?'>
        <AdminLayout>
          <Route exact path="/" component={Login} />
        </AdminLayout>
      </Route>
      <Redirect from="*" to={"/"} />
    </Switch>
  )

  const ADMIN_ROUTES = (
    <Switch>
      <Route path='/:path?'>
        <AdminLayout>
          <Route exact path="/inspections/:id/rooms/:local_room_id/view_figures_in" component={ViewFiguresIn} />
          <Route exact path="/inspections/:id/rooms/:local_room_id/figures_in" component={RoomFiguresIn} />
          <Route exact path="/inspections/:id/rooms/:local_room_id/figures_out" component={RoomFiguresOut} />
          <Route exact path="/inspections/:id/rooms/:local_room_id" component={Room} />
          <Route exact path="/inspections/:id" component={Inspection} />
          <Route exact path="/" component={Inspections} />
        </AdminLayout>
      </Route>
    </Switch>
  )


  // Effect

  useEffect(() => {
    if (auth.is_authenticated) {
      setRoutes(ADMIN_ROUTES)
    } else {
      setRoutes(PUBLIC_ROUTES)
    }
  }, [auth]);


  // useEffect(() => {
  //   navigator.geolocation.getCurrentPosition(localSavePosition);
  //   if (navigator.geolocation) {
  //     const interval = setInterval(() => {
  //       navigator.geolocation.getCurrentPosition(localSavePosition);
  //     }, 5 * 60 * 1000);
  //     return () => clearInterval(interval);
  //   }
  // }, []);

  // const localSavePosition = (position) => {
  //   savePosition(position, auth)
  // }

  return (
    <>
      <ToastContainer />
      {routes}
    </>
  );
}