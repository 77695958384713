import { loadingWhite } from "../Placeholder/LoadingPage"

export default function Submit({ label, mainColor, width, loading}) {

    function color() {
        var result = "bg-green-600 hover:bg-green-700 focus:ring-green-500 focus:ring-offset-green-200"
        if (mainColor === "blue") {
            result = "bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200"
        }
        if (mainColor === "red") {
            result = "bg-red-600 hover:bg-red-700 focus:ring-red-500 focus:ring-offset-red-200"
        }
        if (mainColor === "green") {
            result = "bg-green-600 hover:bg-green-700 focus:ring-green-500 focus:ring-offset-green-200"
        }
        if (mainColor === "orange") {
            result = "bg-orange-600 hover:bg-orange-700 focus:ring-orange-500 focus:ring-offset-orange-200"
        }
        return result
    }

    return (

        <div className={width}>
            <div className="relative">
                <button
                    type="submit"
                    name="submit"
                    id="submit"
                    className={`w-full flex justify-center items-center flex-shrink-0 h-10 text-base font-semibold text-white rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 ${color()}`}
                >
                    {loading ? loadingWhite() : label}
                </button>

            </div>
        </div>


    )
}