import { main_server, api_version } from "../api";
import { returnRequestData } from "js-base-utils";
import { db } from "../db";
import { safeMaxOfArray } from "./utilities";
var jaro = require("wink-jaro-distance");

export const createRoom = async (obj) => {
  await db.rooms.add({ ...obj, actual_created_at: new Date() });
};

export const updateRoom = async (id, obj) => {
  db.rooms.where("local_id").equals(id).modify(obj);
};

export const updateItem = async (id, obj) => {
  db.items.where("local_id").equals(id).modify(obj);
};

export const DEFAULT_ROOM = {
  done: false,
  room_name: "",
};

export const addLocalRoom = async (inspection_id, position) => {
  if (inspection_id) {
    await db.rooms.add({
      ...DEFAULT_ROOM,
      inspection_id: inspection_id,
      position: parseInt(position),
      actual_created_at: new Date(),
    });
  }
  return false;
};

export const addLocalItem = async (obj) => {
  await db.items.add({ ...obj, actual_created_at: new Date() });
};

export const addLocalFigure = async (obj) => {
  await db.figures.add({ ...obj, actual_created_at: new Date() });
};

export const updateLocalFigure = async (id, obj) => {
  db.figures.where("local_id").equals(id).modify(obj);
};

export const downloadRemoteRoomsItems = async (inspection_id, token) => {
  var requestResponse = await returnRequestData(
    `${main_server}${api_version}/rooms`,
    "GET",
    { inspection_id },
    null,
    null,
    token
  );
  var responseData = requestResponse.data;
  if (responseData.status >= 200 && responseData.status < 300) {
    return responseData;
  } else {
    alert("Erro inesperado! Caso continue, por favor contate o requerente!");
    return false;
  }
};

// Called Functions

export const mergeLocalRooms = async (inspection_id, token) => {
  var confirm = false;
  if (
    window.confirm(
      "Confirma download? Cômodos e itens salvos off-line serão apagados."
    )
  ) {
    confirm = true;
  }
  if (confirm) {
    var rooms = await downloadRemoteRoomsItems(inspection_id, token);
    var items = [];
    rooms = rooms.rooms;
    if (rooms && rooms.length > 0) {
      await db.rooms.where("inspection_id").equals(inspection_id).delete();
      for (var i = 0; i < rooms.length; ++i) {
        const local_room_id = await db.rooms.add({
          ...DEFAULT_ROOM,
          ...rooms[i],
          actual_created_at: new Date(),
        });
        items = [];
        items = rooms[i].items;
        for (var j = 0; j < items.length; ++j) {
          await db.items.add({
            local_room_id,
            inspection_id,
            ...items[j],
            actual_created_at: new Date(),
          });
        }
      }
    }
    return false;
  } else {
    return false;
  }
};

export const deleteLocalRoom = async (room_id) => {
  var confirm = false;
  if (
    window.confirm(
      "Confirma exclusão? Cômodo e itens salvos off-line serão apagados."
    )
  ) {
    confirm = true;
  }
  if (confirm) {
    var room = await db.rooms.where("local_id").equals(room_id).toArray();
    if (room) {
      room = room[0];
      if (room?.id) {
        await db.deleatables.add({
          room_id: room?.id,
          inspection_id: room.inspection_id,
          actual_created_at: new Date(),
        });
      }
    }
    await db.rooms.where("local_id").equals(room_id).delete();
    return false;
  } else {
    return false;
  }
};

export const deleteLocalItem = async (item_id, inspection_id) => {
  var confirm = false;
  if (window.confirm("Confirma exclusão? Item off-line será apagados.")) {
    confirm = true;
  }
  if (confirm) {
    var item = await db.items.where("local_id").equals(item_id).toArray();
    if (item) {
      item = item[0];
      if (item?.id) {
        await db.deleatables.add({
          item_id: item?.id,
          inspection_id: parseInt(inspection_id),
          actual_created_at: new Date(),
        });
      }
    }
    await db.items.where("local_id").equals(item_id).delete();
    return false;
  } else {
    return false;
  }
};

export const deleteLocalFigure = async (figure_id, inspection_id) => {
  var confirm = true;
  // if (window.confirm("Confirma exclusão? Item off-line será apagados.")) {
  //     confirm = true
  // }
  if (confirm) {
    var figure = await db.figures.where("local_id").equals(figure_id).toArray();
    if (figure) {
      figure = figure[0];
      if (figure?.id) {
        await db.deleatables.add({
          figure_id: figure?.id,
          inspection_id: parseInt(inspection_id),
          actual_created_at: new Date(),
        });
      }
    }
    await db.figures.where("local_id").equals(figure_id).delete();
    return false;
  } else {
    return false;
  }
};

// Apply default items
export const applyDefaultItemsToRoom = async (local_room_id) => {
  var message = "Itens Criados!";
  //
  var room = await db.rooms.where("local_id").equals(local_room_id).toArray();
  room = room[0];
  // message
  var items = await db.items
    .where("local_room_id")
    .equals(parseInt(room.local_id))
    .toArray();
  if (items.length > 0) {
    message = "Itens não criados, pois o cômodo já possui um ou mais itens.";
    alert(message);
    return false;
  } else {
    for (var i = 0; i < DEFAULT_ROOM_ITEMS.length; ++i) {
      if (
        compareArrayDistance(DEFAULT_ROOM_ITEMS[i].names, room.room_name) > 0.8
      ) {
        items = await db.items
          .where("local_room_id")
          .equals(parseInt(room.local_id))
          .toArray();
        if (items.length === 0) {
          for (var j = 0; j < DEFAULT_ROOM_ITEMS[i].items.length; ++j) {
            items = await db.items
              .where("local_room_id")
              .equals(parseInt(room.local_id))
              .toArray();
            await addLocalItem({
              name: DEFAULT_ROOM_ITEMS[i].items[j],
              inspection_id: parseInt(room.inspection_id),
              local_room_id: parseInt(room.local_id),
              position: safeMaxOfArray(items.map((a) => a.position)) + 1,
            });
          }
        }
      }
    }
    alert(message);
    return false;
  }
};

const DEFAULT_ROOM_ITEMS = [
  {
    names: ["frente", "faxada", "Frente"],
    items: ["Portão", "Muro", "Piso", "Elétrica"],
  },
  {
    names: ["Padrão de Energia"],
    items: [
      "Visor Medidor Principal",
      "Medidor Principal",
      "Leitura Medidor Principal",
    ],
  },
  {
    names: [
      "quintal",
      "entrada",
      "quintal fundos",
      "quintal frente",
      "Área Externa",
      "Área da Piscina",
      "Quintal",
      "Quintal dos Fundos",
    ],
    items: ["Paredes", "Piso", "Elétrica", "Hidráulica"],
  },
  {
    names: [
      "garagem",
      "varanda",
      "Garagem",
      "Varanda",
      "Entrada Social",
      "Hall",
      "Hall de Entrada",
    ],
    items: ["Teto", "Paredes", "Piso", "Elétrica", "Hidráulica"],
  },
  {
    names: [
      "corredor",
      "corredores",
      "Corredor Externo",
      "Corredor Externo (direito)",
      "Corredor Externo (esquerdo)",
      "Corredor Externo (fundo)",
    ],
    items: ["Porta", "Teto", "Paredes", "Piso", "Elétrica"],
  },
  {
    names: [
      "sala",
      "Cômodo de Despejo",
      "Depósito",
      "Edícula",
      "Sacada",
      "Jardim de Inverno",
      "Área de Luz",
      "Escada",
      "copa",
      "sala de tv",
      "sala de estar",
      "sala de jantar",
      "despejo",
      "Corredor Interno",
      "Despensa",
      "Copa",
      "Sala",
      "Salão",
      "Sala de Jantar",
      "Sala de Estar",
      "Sala / Cozinha",
      "Sala de TV",
    ],
    items: ["Porta", "Janela", "Teto", "Paredes", "Piso", "Elétrica"],
  },
  {
    names: [
      "quarto",
      "Escritório",
      "dormitório",
      "escritório",
      "Closet",
      "closet",
      "despejo",
      "Dormitório 01",
      "Dormitório 01 (frente)",
      "Dormitório 01 (fundos)",
      "Dormitório 01 (meio)",
      "Dormitório 01 (suíte)",
      "Dormitório 01 (direito)",
      "Dormitório 01 (esquerdo)",
      "Dormitório 02",
      "Dormitório 02 (frente)",
      "Dormitório 02 (fundos)",
      "Dormitório 02 (meio)",
      "Dormitório 02 (suíte)",
      "Dormitório 02 (direito)",
      "Dormitório 02 (esquerdo)",
      "Dormitório 03",
      "Dormitório 03 (frente)",
      "Dormitório 03 (fundos)",
      "Dormitório 03 (meio)",
      "Dormitório 03 (suíte)",
      "Dormitório 03 (direito)",
      "Dormitório 03 (esquerdo)",
    ],
    items: ["Porta", "Janela", "Teto", "Paredes", "Piso", "Elétrica"],
  },
  {
    names: [
      "cozinha",
      "Cozinha Externa",
      "Área de Lazer",
      "Área da Churrasqueira",
      "área gourmet",
      "área de lazer",
      "Cozinha",
    ],
    items: [
      "Porta",
      "Janela",
      "Teto",
      "Parede",
      "Piso",
      "Pia",
      "Armário",
      "Elétrica",
      "Hidráulica",
    ],
  },
  {
    names: [
      "banheiro",
      "banheiro suíte",
      "banheiro social",
      "lavabo",
      "Lavabo",
      "Banheiro Social",
      "Banheiro Social (interno)",
      "Banheiro Social (externo)",
      "Banheiro",
      "Banheiro Suíte 01",
      "Banheiro Suíte 02",
      "Banheiro Suíte 03",
    ],
    items: [
      "Porta",
      "Janela",
      "Teto",
      "Parede",
      "Piso",
      "Pia",
      "Gabinete",
      "Armário de Parede",
      "Espelho",
      "Vaso Sanitário",
      "Box",
      "Chuveiro",
      "Elétrica",
      "Hidráulica",
      "Acessórios",
    ],
  },
  {
    names: ["lavanderia", "área de serviço", "Lavanderia"],
    items: [
      "Porta",
      "Janela",
      "Teto",
      "Parede",
      "Piso",
      "Tanque",
      "Elétrica",
      "Hidráulica",
    ],
  },
];

export const compareArrayDistance = (array, text) => {
  var distances = [];
  for (var i = 0; i < array.length; ++i) {
    distances.push(jaro(array[i], text).similarity);
  }
  return safeMaxOfArray(distances);
};
