import { api_version, main_server } from "../../lib/api.js"
import { returnRequestData } from 'js-base-utils'
import { mapFormError, forceArray } from "js-base-utils"

export async function formSubmitter(formData, endPoint, successMessage = "Sucesso!", errorMessage = "Erro Inesperado!", responseCallback = false, additionalData = {}) {
    if (!additionalData) {
        additionalData = {}
    }
    // console.log(formData)
    var requestResponse = await returnRequestData(
        `${main_server}${api_version}${endPoint}`,
        "POST",
        { ...formData, ...additionalData},
        null,
        null,
        null
    );
    var responseData = requestResponse.data;
    if (responseData.status >= 200 && responseData.status < 300) {
        if (responseCallback) {
            responseCallback(responseData)
        }
        // toast.success(successMessage);
    } else {
        if (responseData.error) {
            // toast.error(forceArray(mapFormError(responseData.error)).join(" "));
            responseCallback(responseData)
        } else {
            responseCallback(responseData)
            // toast.error(errorMessage);
        }
    }
    return false
}

export const avoidSubmit = async (e) => {
    e.preventDefault();
    return false
}