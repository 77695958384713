import FormLayout from "../components/Forms/FormLayout";
import { useState } from "react";
// Redux
import { useSelector, useDispatch } from 'react-redux'
// import { AuthStateInterface, LoginPropsInterface } from "../store/interfaces"
import { mapFormError, forceArray } from "js-base-utils"
import LoginForm from "../components/Forms/components/login/LoginForm"
import OtpVerifierForm from "../components/Forms/components/login/OtpVerifierForm"
import logo from "../assets/images/logo.png"

export default function Comp(props) {

    const dispatch = useDispatch();

    const [step, setStep] = useState("login")
    const [error, setError] = useState({})

    const [form, setForm] = useState({})


    // 
    const responseCallback = (data) => {
        setError({})
        if (data.require_otp) {
            setStep("otp")
        } else {
            if (data.is_authenticated) {
                dispatch({ type: 'AUTH_SUCCESS', data, isLoading: false })
            } else {
                setError({ error: forceArray(mapFormError(data.error)) })
            }
        }
    }

    const formStateCallback = (data) => {
        setForm({ ...form, ...data })
    }

    // 

    const otpCallback = (data) => {
        // setError()
    }

    const responseOtpCallback = (data) => {
        if (data.is_authenticated) {
            dispatch({ type: 'AUTH_SUCCESS', data, isLoading: false })
            setError({})
        } else {
            setError({ error: forceArray(mapFormError(data.error)) })
        }
    }


    return (
        <div className="h-screen bg-white relative flex flex-col space-y-10 justify-center items-center">
            <header>
                <a aria-label="logo" className="relative justify-start items-start" href="/">
                    <img className="w-36" src={logo} alt={"logo"} />
                </a>
            </header>
            {step === "login" &&
                <LoginForm
                    endPoint="/authenticate"
                    title={"Área Administrativa"}
                    description={""}
                    responseCallback={responseCallback}
                    formCallback={formStateCallback}
                    error={error}
                    loginLabel={"Usuário/E-mail"}
                    loginName={"email"}
                />
            }
            {step === "otp" &&
                <OtpVerifierForm
                    endPoint="/authenticate_otp"
                    title={"Autenticação de 2 fatores"}
                    description={"Insira o código do seu aplicativo de autenticação"}
                    responseCallback={responseOtpCallback}
                    formCallback={otpCallback}
                    form={form}
                    additionalData={form}
                    error={error}
                />
            }

            {/* <p>
        Não possui cadastro?
        <a className="text-blue-600 font-bold hover:bg-blue-200 hover:underline hover:px-4 hover:py-3 p-2 rounded-full" href="/">
          Crie agora
        </a>
      </p> */}
            <div className="bg-white shadow-lg rounded p-6 w-96 flex flex-col items-center justify-center" >
                <p>
                    v 1.0.1
                </p>
            </div>
        </div>
    );
}
