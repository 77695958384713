import useSWR, { useSWRConfig } from 'swr'
import axios from 'axios';
import { api_version, main_server } from "./api.js"

const getRequest = {
    method: 'GET'
};

const fetcher = async (url, params, configRequest) =>
    axios({ ...configRequest, url, params: JSON.parse(params) }).then(res => res.data)

export const GetRequestSWR = (url, params = null, revalidateOnFocus = true, use_main_server = true, token = null) => {
    if (use_main_server) {
        url = `${main_server}${api_version}${url}`
    }
    var configRequest = getRequest;

    if (token) {
        configRequest['headers'] = {};
        configRequest['headers']['Authorization'] = token;
    }

    const { data, error } = useSWR(
        [url, params, configRequest],
        fetcher,
        revalidateOnFocus
    )

    const { cache, mutate, } = useSWRConfig()

    return {
        isLoading: !data && !error,
        data,
        error,
        mutate,
        cache,
    }
}