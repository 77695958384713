import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import { db } from "../../lib/db"
import { useLiveQuery } from "dexie-react-hooks";
import { addLocalRoom, mergeLocalRooms } from "../../lib/actions/rooms"
import { safeMaxOfArray } from "../../lib/actions/utilities"
import { uploadRooms } from "../../lib/actions/upload"
import { PlusCircleIcon } from '@heroicons/react/solid'
import PageHeader from "../PagesNavigation/PageHeader"
import RoomForm from "./components/RoomForm"
import ApplyRoomModel from "./components/ApplyRoomModel"

export default function App(props) {

    // Redux
    const auth_token = useSelector(state => state.auth.auth_token)

    // State
    const [inspection, setInspection] = useState({})
    const [uploadInfo, setUploadInfo] = useState({})
    const searchInspection = useLiveQuery(async () => {
        return await db.inspections.where("id").equals(parseInt(props?.match?.params?.id)).toArray()
    });
    const localRooms = useLiveQuery(async () => {
        return await db.rooms.where("inspection_id").equals(parseInt(props?.match?.params?.id)).toArray()
    });
    useEffect(() => {
        if (searchInspection && searchInspection[0]) {
            setInspection(searchInspection[0])
        }
    }, [searchInspection])

    const inspectionBackground = (type) => {
        var bgColor = "bg-blue-600"
        if (type?.includes("Saída")) {
            bgColor = "bg-red-800"
        } else if (type?.includes("Entrada")) {
            bgColor = "bg-green-600"
        }
        return bgColor
    }

    const downloadRooms = async () => {
        await mergeLocalRooms(inspection.id, auth_token);
    }

    const changeUploadInfo = (obj) => {
        setUploadInfo(obj)
    }

    const initUploadRooms = async () => {
        await uploadRooms(inspection.id, auth_token, changeUploadInfo)
    }

    const addRoom = async () => {
        await addLocalRoom(inspection.id, safeMaxOfArray(localRooms.map(a => a.position)) + 1)
    }

    return (
        <div className="min-h-screen bg-gray-100 pb-40">
            <PageHeader
                showBackButton
                title="Editar Vistoria"
            />
            <div className="w-full bg-gray-50 flex">
                <div className="h-56 w-full absolute flex justify-center items-center">
                    <img
                        className="object-cover h-32 w-32 rounded-full shadow-lg"
                        src={inspection.image}
                        alt={inspection.id}
                    />
                </div>
                <div className={`h-64 mx-0 w-full rounded-3xl shadow-md ${inspectionBackground(inspection.current_inspection_type)}`}>
                    <div className="h-1/2 w-full flex justify-between items-baseline px-3 py-5">
                        <button type="button" onClick={downloadRooms} className="z-10 py-2 px-4 cursor-pointer bg-white hover:bg-gray-200 focus:ring-gray-900 focus:ring-offset-gray-900 transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
                            Download
                        </button>
                        <button type="button" onClick={initUploadRooms} className="z-10 py-2 px-4 cursor-pointer bg-white hover:bg-gray-200 focus:ring-gray-900 focus:ring-offset-gray-900 transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
                            Upload
                        </button>
                    </div>
                    <div className="bg-white h-1/2 w-full rounded-3xl flex flex-col justify-end items-bottom py-4">
                        <div className="w-full h-1/2 flex flex-col justify-center items-center px-4">
                            <h1 className="text-gray-700 font-bold text-center">
                                {inspection.full_address_no_city}
                            </h1>
                            <h1 className="text-gray-700 text-sm text-center">
                                {inspection.city}
                            </h1>
                            <div className="text-gray-700 text-sm text-center">
                                {inspection.description}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {!uploadInfo?.uploading &&
                <>
                    <div>
                        {localRooms && localRooms.sort((a, b) => { if (a.position > b.position) { return 1; } else { return -1 } }).map((el) => (
                            <RoomForm
                                key={el.local_id}
                                el={el}
                                inspection_id={props?.match?.params?.id}
                            />
                        ))}
                    </div>
                    <button type="button" onClick={addRoom} className="w-11/12 sm:w-1/2 md:w-1/4 my-4 mx-auto py-2 px-4 flex justify-center items-center bg-gray-500 hover:bg-gray-700 focus:ring-gray-500 focus:ring-offset-gray-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                        <PlusCircleIcon className="h-6 w-6 text-white" />
                    </button>
                </>
            }
            {uploadInfo?.uploading &&

                <div className="shadow-lg mx-auto my-12  rounded-xl w-11/12 md:w-1/2 p-4 bg-white relative overflow-hidden">
                    <a href="#" className="w-full h-full block">
                        <div className="w-full flex items-center">
                            {uploadInfo?.image &&
                                <div className="block relative">
                                    <img alt="" src={uploadInfo?.image} className="mx-auto object-cover rounded-sm h-28 w-28 " />
                                </div>
                            }
                            <div className="flex flex-col items-center ml-2">
                                <span>
                                    {uploadInfo?.message}
                                </span>
                            </div>
                        </div>
                        <div className="flex items-center justify-between my-2">
                            <p className="text-gray-500 text-sm">
                                {uploadInfo?.subtitle}
                            </p>
                        </div>
                        <div className="w-full h-2 bg-gray-400 rounded-full">
                            <div
                                class={`h-full text-center text-xs text-white ${uploadInfo?.color} rounded-full`}
                                style={{ width: `${parseInt(uploadInfo?.complete)}%` }}
                            />
                        </div>
                    </a>
                </div>
            }
        </div>
    );
}