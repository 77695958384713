import FileUploaderOrderable from "../components/Inspections/components/FileUploaderOrderable"
import HeaderMetadata from "../components/HeaderMetadata/HeaderMetadata"
export default function App(props) {
    return (
        <>
            <HeaderMetadata
                title="Fotos de Saída"
                description="Fotos de Saída"
            />
            <FileUploaderOrderable 
                title={"Fotos de Saída"}
                figure_type={"inspection_out"}
                inspection_id={props?.match?.params?.id}
                local_room_id={props?.match?.params?.local_room_id}
                path="figures"
                model="figure"
            />
        </>
    );
}