import React, { useState, useEffect } from "react";

import CreatableSelect from "react-select/creatable";

export default function App(props) {
  const [value, setValue] = useState({
    label: props.initialValue,
    value: props.initialValue,
  });

  const [initialLoad, setInitialLoad] = useState(false);

  // Effect
  useEffect(() => {
    if (!initialLoad) {
      props.callback(props.initialValue);
      setInitialLoad(true);
    }
  }, [props]);

  const onChange = async (selectedOptions) => {
    setValue(selectedOptions);
    var obj = {};
    obj[props.name] = selectedOptions.value;
    props.callback(obj);
  };

  return (
    <CreatableSelect
      isClearable
      options={props.options}
      type="text"
      name={props.name}
      placeholder={props.placeholder}
      value={value}
      onChange={onChange}
      formatCreateLabel={(value) => `${value}`}
      className="
            flex-1 
            appearance-none
            w-full 
            py-0.5 px-0 
            bg-white 
            text-gray-900 
            placeholder-gray-400 
            text-lg 
            font-medium
            focus:outline-none
            focus:border-b-2 
            border-gray-900 
            "
    />
  );
}
