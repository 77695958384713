import * as utilities from "js-base-utils"

// safe max of an array
export function safeMaxOfArray(array_of_values) {
    var result = 0;
    const max = Math.max(...array_of_values)
    if (max > 0) {
        result = max
    }
    return result
}


// File Converter
export const fileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export async function base64ToFile(dataUrl, fileName, mime_type) {
    const res = await fetch(dataUrl);
    const blob = await res.blob();
    return new File([blob], fileName, { type: mime_type });
}


// FILE COMPRESSOR
// Compress step 01: Read
export function readUploadedFile(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve, reject) => {
        reader.onerror = (error) => {
            reader.abort();
            reject(console.log(error));
        };
        reader.onload = (event) => {
            const img = new Image();
            img.src = event.target.result;
            resolve(img);
        };
    });
}
// Compress step 02: Scale
export function compressor(img, width) {
    return new Promise((resolve, reject) => {
        img.onerror = (error) => {
            img.abort();
            reject(console.log(error));
        };
        img.onload = () => {
            const scaleFactor = width / img.width;
            const elem = document.createElement('canvas');
            const ctx = elem.getContext('2d');
            elem.width = width;
            elem.height = img.height * scaleFactor;
            ctx.drawImage(img, 0, 0, width, img.height * scaleFactor);
            resolve(ctx)
        };
    });
}
// Compress step 03: Create final file
export function makeBlob(ctx, fileName, type, quality) {
    return new Promise((resolve, reject) => {
        ctx.canvas.toBlob((blob) => {
            const file = new File([blob], fileName, {
                type,
                lastModified: Date.now()
            });
            resolve(file);
        }, type, quality);
    });
}

export const compress = async (file, requireCompression, maxWidth, quality) => {
    var fileBlob = file;
    if (requireCompression && utilities.image_format.includes(file.name.split(".").slice(-1)[0])) {
        const readFile = await readUploadedFile(file);
        const compressedFile = await compressor(readFile, maxWidth);
        fileBlob = await makeBlob(compressedFile, file.name, file.type, quality);
    }
    return fileBlob
}


export const createUrlFromBlob = (blob) => {
    return URL.createObjectURL(blob, blob.type);
}