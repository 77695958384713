import { mapFormError, forceArray } from "js-base-utils"


export default function ErrorPlaceholder(error = {}) {

    return (

        <div className="h-96 flex justify-center items-center">
            <div className="max-w-sm bg-white border-2 border-gray-200 p-6 rounded-md tracking-wide shadow-lg">
                <div id="header" className="flex items-center mb-4">

                    <svg xmlns="http://www.w3.org/2000/svg" className="h-20 w-20 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>

                    <div id="header-text" className="leading-5 ml-6 sm">
                        <h4 id="name" className="text-xl font-semibold">
                            {error?.response?.status || 'Erro'}
                        </h4>
                        <h5 id="job" className="font-semibold text-red-500">
                            {forceArray(mapFormError(error?.response?.data?.errors)).join(" ") || 'Desculpe, algo está errado'}
                        </h5>
                    </div>
                </div>
                <div>
                    <p className="italic text-gray-600 text-center">
                        {error?.message || 'Tente novamente mais tarde'}
                    </p>
                </div>
            </div>
        </div>

    )
}