import ViewFigures from "../components/Inspections/ViewFigures"
import HeaderMetadata from "../components/HeaderMetadata/HeaderMetadata"
export default function App(props) {
    return (
        <>
            <HeaderMetadata
                title="Ver Fotos de Entrada"
                description="Ver Fotos de Entrada"
            />
            <ViewFigures
                match={props.match}
                title={"Fotos de Entrada"}
                figure_type={"inspection_in"}
                inspection_id={props?.match?.params?.id}
                local_room_id={props?.match?.params?.local_room_id}
                path="figures"
                model="figure"
            />
        </>
    );
}