import React, { useState, useEffect, useCallback } from 'react';
import update from 'immutability-helper'
import { useSelector } from 'react-redux'
import { db } from "../../../lib/db"
import { addLocalFigure } from "../../../lib/actions/rooms"
import { safeMaxOfArray, compress, createUrlFromBlob } from "../../../lib/actions/utilities"
import { useLiveQuery } from "dexie-react-hooks";
import { loadingWhite } from "../../Placeholder/LoadingPage"
import {Card} from "./LocalImageOfItem"
import { usePreview, Preview } from 'react-dnd-preview'
import { deleteLocalFigure } from "../../../lib/actions/rooms"
import PageHeader from "../../PagesNavigation/PageHeader"

export default function App(props) {
    // Redux
    const auth_token = useSelector(state => state.auth.auth_token)

    // State
    const [room, setRoom] = useState({})
    const [loadingLocal, setLoadingLocal] = useState(false)
    const [loadingRemote, setLoadingRemote] = useState(false)
    const [cards, setCards] = useState([])
    const searchRoom = useLiveQuery(async () => {
        return await db.rooms.where("local_id").equals(parseInt(props?.local_room_id)).toArray()
    });
    useEffect(() => {
        if (searchRoom && searchRoom[0]) {
            setRoom(searchRoom[0])
        }
    }, [searchRoom])
    const localFigures = useLiveQuery(async () => {
        return await db.figures.where({local_room_id: parseInt(props?.local_room_id), figure_type: props?.figure_type}).toArray()
    });
    useEffect(() => {
        if (localFigures && cards.length === 0) {
            setCards(localFigures.sort((a, b) => a.position - b.position))
        }
    }, [localFigures, cards])
    const roomFigures = useLiveQuery(async () => {
        return await db.figures.where({local_room_id: parseInt(props?.local_room_id), figure_type: props?.figure_type}).toArray()
    });

    const MyPreview = () => {
        const preview = usePreview()
        const element = Preview
        if (!preview.display) {
          return null
        }
        const {itemType, item, style} = preview;
        return <div className="item-list__item" style={{...style, width: '25%'}}><img className="" src={createUrlFromBlob(item.base64)} alt={"logo"} /></div>
      }



    // Select Files
    const addNewFiles = async (e) => {
        e.preventDefault();
        setLoadingLocal(true)
        var files = Object.values(e.target.files);
        files.reverse();
        var maxPosition = safeMaxOfArray(roomFigures.map(a => a.position))
        for (var i = 0; i < files.length; ++i) {
            var dataFile = await compress(files[i], true, 780, 1)
            // var base64 = await fileToBase64(dataFile)
            await addLocalFigure({
                position: maxPosition + i + 1,
                local_room_id: parseInt(props?.local_room_id),
                room_id: room.id,
                figure_type: props?.figure_type,
                filename: files[i].name,
                mime_type: files[i].type,
                size: files[i].size,
                base64: dataFile,
            })
        }
        setLoadingLocal(false);
        // Re-fetch localFigures
        const updatedFigures = await db.figures.where({
            local_room_id: parseInt(props?.local_room_id),
            figure_type: props?.figure_type
        }).toArray();
        setCards(updatedFigures.sort((a, b) => a.position - b.position));
    }

    // UploadFiles
    const uploadRoomFiles = async () => {
        // setLoadingRemote(true)
        // var files = localFigures
        // for (var i = 0; i < files.length; ++i) {
        //     var {base64, ...body} = files[i]
        //     body[props?.model] = await base64ToFile(files[i].base64, files[i].filename, files[i].mime_type);
        //     await uploadFigure("figures", body, auth_token)
        // }
        // setLoadingRemote(false)
    }

    const saveOrderToDatabase = async (updatedCards) => {
        const updates = updatedCards.map((card, index) => ({
            ...card,
            position: index + 1
        }));
        await db.transaction('rw', db.figures, async () => {
            for (let card of updates) {
                await db.figures.update(card.local_id, { position: card.position });
            }
        });
    };

    const deleteLocalFigureFunction = async (local_id, inspection_id) => {
        await deleteLocalFigure(local_id, inspection_id);
        // Re-fetch localFigures
        const updatedFigures = await db.figures.where({
            local_room_id: parseInt(props?.local_room_id),
            figure_type: props?.figure_type
        }).toArray();
        setCards(updatedFigures.sort((a, b) => a.position - b.position));
    };

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        setCards((prevCards) => {
            const newCards = update(prevCards, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevCards[dragIndex]],
                ],
            });
            saveOrderToDatabase(newCards);
            return newCards;
        });
    }, []);

    return (
        <div className="min-h-screen bg-gray-100 pb-40">
            <PageHeader
                showBackButton
                title={`${props.title} | ${room.room_name}`}
            />
            <div className="flex justify-around items-baseline px-3 py-5">
                <label
                    className="
                        w-64
                        flex flex-col
                        items-center
                        px-4
                        py-2
                        bg-blue-500
                        rounded-md
                        shadow-md
                        tracking-wide
                        border border-blue
                        cursor-pointer
                        hover:bg-blue-600 hover:text-white
                        text-white
                        font-medium
                        ease-linear
                        transition-all
                        duration-150
                    "
                >
                    <span className="text-base leading-normal">
                        {loadingLocal ? loadingWhite() : "+ Fotos"}
                    </span>
                    <input
                        type="file"
                        name="file"
                        accept="image/*"
                        multiple={true}
                        onChange={addNewFiles}
                        className="hidden"
                    />
                </label>
            </div>
            <div className="">
                {cards && cards.map((el, index) => (
                    <Card
                        key={el.local_id} 
                        index={index}
                        id={el.local_id} 
                        el={el} 
                        text={el.local_id}
                        moveCard={moveCard}
                        inspection_id={props?.inspection_id}
                        deleteLocalFigureFunction={deleteLocalFigureFunction}
                    />
                ))}
                <MyPreview />
            </div>
        </div>
    );
}