import { main_server, api_version } from "../api"
import { returnRequestData } from 'js-base-utils'
import { db } from "../db"
import { createUrlFromBlob } from "./utilities"
// import { uploadLocation } from "./geolocation"

export const createServerModel = async (path, obj, token) => {
    var requestResponse = await returnRequestData(
        `${main_server}${api_version}/${path}`,
        "POST",
        obj,
        null,
        null,
        token
    );
    var responseData = requestResponse.data;
    if (responseData.status >= 200 && responseData.status < 300) {
        return (responseData)
    } else {
        alert("Erro inesperado! Caso continue, por favor contate o requerente!");
        return false
    }
}

export const updateServerModel = async (path, obj, id, token) => {
    var requestResponse = await returnRequestData(
        `${main_server}${api_version}/${path}/${id}`,
        "PATCH",
        obj,
        null,
        null,
        token
    );
    var responseData = requestResponse.data;
    if (responseData.status >= 200 && responseData.status < 300) {
        return (responseData)
    } else {
        alert("Erro inesperado! Caso continue, por favor contate o requerente!");
        return false
    }
}

export const destroyServerModel = async (path, id, token) => {
    var requestResponse = await returnRequestData(
        `${main_server}${api_version}/${path}/${id}`,
        "DELETE",
        {},
        null,
        null,
        token
        );
    var responseData = requestResponse.data;
    if (responseData.status >= 200 && responseData.status < 300) {
        return (responseData)
    } else {
        alert("Erro inesperado! Caso continue, por favor contate o requerente!");
        return false
    }
}

export const uploadFigure = async (path, body, token) => {
    var requestResponse = await returnRequestData(`${main_server}${api_version}/${path}`, "UPLOAD", body, null, null, token);
    var responseData = requestResponse.data;
    if (responseData.status >= 200 && responseData.status < 300) {
        return (responseData)
    } else {
        alert("Erro inesperado! Caso continue, por favor contate o requerente!");
        return false
    }
}


export const uploadRooms = async (inspection_id, token, infoCallback) => {
    var confirm = false;
    if (window.confirm("Confirma upload? Todas as alterações serão enviadas ao servidor.")) {
        confirm = true
    }
    if (confirm) {
        infoCallback({ uploading: true, message: "Iniciando upload!", color: "bg-blue-600", subtitle: `Não feche a página até terminar!`, total: 0, i: 0, complete: 0 });
        var rooms = await db.rooms.where("inspection_id").equals(parseInt(inspection_id)).toArray();
        // Destroy marked rooms and items
        var deleatables = await db.deleatables.toArray();
        var deleteFromServer;
        var deleatables_complete = 0
        for (var i = 0; i < deleatables.length; ++i) {
            infoCallback({ uploading: true, message: "Sincronizando cômodos e itens deletados", color: "bg-red-600", subtitle: `Deletando ${i + 1} de ${deleatables.length}`, total: deleatables.length, i: i + 1, complete: ((deleatables_complete / deleatables.length) * 100) })
            await new Promise(resolve => setTimeout(resolve, 1000));
            if (deleatables[i].room_id > 0) {
                deleteFromServer = await destroyServerModel("rooms", deleatables[i].room_id, token)
            }
            if (deleatables[i].item_id > 0) {
                deleteFromServer = await destroyServerModel("items", deleatables[i].item_id, token)
            }
            await db.deleatables.where("local_id").equals(deleatables[i].local_id).delete();
        }
        // upload rooms
        if (rooms && rooms.length > 0) {
            var rooms_complete = 0
            var roomUpload;
            var items = [];
            for (var i = 0; i < rooms.length; ++i) {
                infoCallback({ uploading: true, message: "Subindo texto dos cômodos", color: "bg-blue-600", subtitle: `Carregando cômodo ${i + 1} de ${rooms.length}`, total: rooms.length, i: i + 1, complete: ((rooms_complete / rooms.length) * 100) })
                var safe_room_id = rooms[i].id
                // create/update room
                if (rooms[i].id > 0) {
                    roomUpload = await updateServerModel("rooms", rooms[i], rooms[i].id, token);
                } else {
                    roomUpload = await createServerModel("rooms", rooms[i], token);
                    await db.rooms.where('local_id').equals(rooms[i].local_id).modify({ id: roomUpload["room_id"] });
                    safe_room_id = roomUpload["room_id"]
                }
                // create/update items
                var itemUpload;
                items = await db.items.where("local_room_id").equals(parseInt(rooms[i].local_id)).toArray();
                for (var j = 0; j < items.length; ++j) {
                    if (items[j].id > 0) {
                        itemUpload = await updateServerModel("items", items[j], items[j].id, token);
                    } else {
                        itemUpload = await createServerModel("items", { ...items[j], room_id: rooms[i].id > 0 ? rooms[i].id : roomUpload["room_id"] }, token);
                        await db.items.where('local_id').equals(items[j].local_id).modify({ id: itemUpload["item_id"] });
                    }
                }
                rooms_complete = rooms_complete + 1;
            }
            infoCallback({ uploading: true, message: "Subindo texto dos cômodos", color: "bg-blue-600", subtitle: `Cômodos carregados!`, total: rooms.length, i: i, complete: 100 })
        }
        // upload figures
        rooms = []
        rooms = await db.rooms.where("inspection_id").equals(parseInt(inspection_id)).toArray();
        if (rooms && rooms.length > 0) {
            for (var ri = 0; ri < rooms.length; ++ri) {
                var figures_complete = 0
                // create/update figures
                var figureUpload;
                var figures = await db.figures.where({local_room_id: parseInt(rooms[ri].local_id)}).toArray();
                figures.sort((a, b) => b.position - a.position);
                if (rooms[ri].id) {
                    for (var k = 0; k < figures.length; ++k) {
                        infoCallback({ uploading: true, image: createUrlFromBlob(figures[k].base64), message: `Carregando fotos do cômodo ${ri + 1} de ${rooms.length}`, color: "bg-red-600", subtitle: `Foto ${k + 1} de ${figures.length}`, total: figures.length, i: ri + 1, complete: ((figures_complete / figures.length) * 100) })
                        if (figures[k].id > 0) {
                        } else {
                            var { base64, ...body } = figures[k]
                            body["figure"] = figures[k].base64;
                            body["room_id"] = rooms[ri].id;
                            body["position"] = null;
                            figureUpload = await uploadFigure("figures", body, token)
                            // await db.figures.where('local_id').equals(figures[k].local_id).modify({ id: figureUpload["figure_id"] });
                            await db.figures.where('local_id').equals(figures[k].local_id).delete();
                        }
                        figures_complete = figures_complete + 1;
                    }
                }
            }
            // finish message
            // finish message
            infoCallback({ uploading: true, message: "Finalizando envio dos dados!", color: "bg-red-600", subtitle: `Cômodos carregados!`, total: rooms.length, i: i, complete: 100 })
            // var locations = await db.locations.toArray();
            // for (var w = 0; w < locations.length; ++w) {
            //     await uploadLocation("user_locations", locations[w], token)
            // }
            infoCallback({ uploading: true, message: "Upload Concluído com Sucesso", color: "bg-red-600", subtitle: `Cômodos carregados!`, total: rooms.length, i: i, complete: 100 })
            await new Promise(resolve => setTimeout(resolve, 1500));
            infoCallback({ uploading: false })
        }
    }
    return false
}