import { db } from "../db"

export const createInspection = async (obj) => {
    await db.inspections.add(obj);
}

export const updateInspection = async (id, obj) => {
    db.inspections.where('id').equals(id).modify(obj)
}

export const downloadInspections = async (remoteInspections) => {
    if (remoteInspections && remoteInspections.length > 0) {
        await db.inspections.clear();
        for (var i = 0; i < remoteInspections.length; ++i) {
            var remoteInspectionData = {
                id: remoteInspections[i]["id"],
                image: remoteInspections[i]["image"],
                creator_name: remoteInspections[i]["creator_name"],
                city: remoteInspections[i]["city"],
                description: remoteInspections[i]["description"],
                keys_id: remoteInspections[i]["keys_id"],
                full_address_no_city: remoteInspections[i]["full_address_no_city"],
                current_inspection_type: remoteInspections[i]["current_inspection_type"],
            };
            createInspection(remoteInspectionData)
        }
    }
    return false
}

export const createUpdateLocalInspections = async (remoteInspections) => {
    if (remoteInspections && remoteInspections.length > 0) {
        for (var i = 0; i < remoteInspections.length; ++i) {
            var remoteInspectionData = {
                id: remoteInspections[i]["id"],
                image: remoteInspections[i]["image"],
                creator_name: remoteInspections[i]["creator_name"],
                city: remoteInspections[i]["city"],
                description: remoteInspections[i]["description"],
                full_address_no_city: remoteInspections[i]["full_address_no_city"],
                keys_id: remoteInspections[i]["keys_id"],
                current_inspection_type: remoteInspections[i]["current_inspection_type"],
            };
            const localInspectionsByLocalId = await db.inspections.where('id').equals(remoteInspections[i]["id"]).toArray();
            if (localInspectionsByLocalId.length === 1) {
                updateInspection(remoteInspections[i]["id"], remoteInspectionData)
            } else {
                createInspection(remoteInspectionData)
            }
        }
    }
    return false
}