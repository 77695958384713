import React, { useState, useEffect } from "react";
import { XCircleIcon, LightningBoltIcon } from "@heroicons/react/solid";
import { resolveDescription, optionsResolver } from "./MagicItemDescription";
export default function App(props) {
  const [selected, setSelected] = useState({});

  //   const callback = (options) => {
  //     setSelected((selected) => options);
  //   };

  const callback = (options, title) => {
    const obj = {};
    obj[title] = options;
    setSelected({ ...selected, ...obj });
  };

  const applyDescription = () => {
    const obj = {};
    obj[props.name] = resolveDescription(selected);
    props.callbackDescription(obj);
    props.toggleDescriptionModal();
  };

  return (
    <div className="bg-white fixed h-full w-full z-10 top-0 right-0 overflow-scroll">
      <div class="flex flex-col mx-auto my-12 max-w-md px-4 py-8 bg-white rounded-lg shadow  sm:px-6 md:px-8 lg:px-10">
        <div class="self-end mb-6 text-xl font-light text-gray-600 sm:text-2xl">
          <button
            type="button"
            onClick={props.toggleDescriptionModal}
            className=" my-1 flex justify-end items-start focus:ring-gray-500 focus:ring-offset-gray-200 transition ease-in duration-200 text-base font-semibold focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg"
          >
            <XCircleIcon className="h-8 w-8 text-gray-900" />
          </button>
        </div>

        <div class="self-center mb-6 text-xl font-light text-gray-600 sm:text-2xl">
          {props.el.name}
        </div>
        {/* {JSON.stringify(selected)} */}
        <div class="mt-8">
          {optionsResolver(props.el.name).map((el, key) => (
            <OptionsRendered key={key} {...el} callback={callback} />
          ))}
          <div class="flex gap-4 item-center">
            {/* <button
              type="button"
              class="py-2 px-4 flex justify-center items-center  bg-red-600 hover:bg-red-700 focus:ring-red-500 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
            >
              Limpar
            </button> */}
            <button
              type="button"
              class="py-2 px-4 flex justify-center items-center  bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
              onClick={applyDescription}
            >
              Criar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function OptionsRendered(props) {
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    props.callback(selected, props.title);
  }, [selected]);

  const selectOption = (option) => {
    if (props.type === "select") {
      setSelected((selected) =>
        selected.includes(option)
          ? selected.filter((i) => i !== option)
          : [...selected, option]
      );
    }
    if (props.type === "radio") {
      setSelected((selected) => [option]);
    }
  };

  return (
    <div>
      <Separator {...props} />
      {props.options.map((el, key) => (
        <Option
          key={key}
          el={el}
          selectOption={selectOption}
          selected={selected}
          counter={props.counter}
          status={props.status}
        />
      ))}
    </div>
  );
}

function Separator(props) {
  return (
    <div class="w-full py-5 mb-2 bg-gray-200 shadow sm:px-6">
      <h3 class="text-lg font-medium leading-6 text-gray-600">
        {props.title}
      </h3>
    </div>
  );
}

function Option(props) {
  const [counter, setCounter] = useState(0);

  const [statusPosition, setStatusPosition] = useState(0);

  const upCounter = () => {
    if (counter > 10) {
      setCounter(0);
    } else {
      setCounter(counter + 1);
    }
  };

  const toggleStatus = () => {
    if (props.status) {
      if (statusPosition + 1 < props.status.length) {
        setStatusPosition(statusPosition + 1);
      } else {
        setStatusPosition(0);
      }
    }
  };

  return (
    <div className="w-full flex flex-row justify-center align-center items-center gap-2">
      {props.counter && (
        <div
          onClick={upCounter}
          class="w-1/5 cursor-pointer px-4 py-5 mb-2 bg-blue-200 border rounded-md shadow sm:px-6 text-lg font-medium leading-6 text-gray-900"
        >
          {`${counter}`}
        </div>
      )}
      <div
        onClick={() =>
          props.selectOption(
            `${counter > 0 ? `${(counter > 0 && counter < 10) ? `0${counter}` : counter} ` : ""}${props.el}${
              props.status ? ` ${props.status[statusPosition]}` : ""
            }`
          )
        }
        className={[
          "w-3/5 cursor-pointer px-4 py-5 mb-2 border rounded-md shadow sm:px-6",
          String(props.selected).includes(props.el)
            ? "bg-green-200"
            : "bg-white",
        ].join(" ")}
      >
        <div class="text-lg font-medium leading-6 text-gray-900">
          {props.el}
        </div>
      </div>
      {props.status && (
        <div
          onClick={toggleStatus}
          class="w-1/5 cursor-pointer px-4 py-5 mb-2 border rounded-md shadow sm:px-6 bg-yellow-200 text-lg font-medium leading-6 text-gray-900 overflow-hidden"
        >
          {`${props.status[statusPosition]}`}
        </div>
      )}
    </div>
  );
}
