import { Link } from "react-router-dom";
export default function App(props) {
    return (
        <div className="overflow-hidden shadow-lg rounded-lg h-90 w-10/12 md:w-1/2 cursor-pointer mx-auto my-8">
            <Link to={`inspections/${props?.el?.id}`}>
                <a className="w-full block h-full">
                    <img alt={props?.el?.id} src={props?.el?.image} className="h-72 w-full object-cover" />
                    <div className="bg-white w-full p-4">
                        <p className="text-blue-500 text-md font-medium">
                            {props?.el?.id} - {props?.el?.creator_name}
                        </p>
                        <p className="text-gray-800 text-xl font-medium mb-2">
                            {props?.el?.city}
                        </p>
                        <p className="text-gray-600 font-light text-md capitalize">
                            {props?.el?.full_address_no_city}
                        </p>
                        <p className="text-indigo-500 text-md font-medium">
                            {props?.el?.keys_id}
                        </p>
                        <p className="text-gray-600 font-light text-md">
                            {props?.el?.description}
                        </p>
                        <div className="flex flex-wrap justify-starts items-center mt-4">
                            {props?.el?.current_inspection_type.includes("Saída") &&
                                <div className="text-xs mr-2 py-1.5 px-4 text-gray-800 font-medium bg-red-300 rounded-2xl">
                                    {props?.el?.current_inspection_type}
                                </div>
                            }
                            {props?.el?.current_inspection_type.includes("Entrada") &&
                                <div className="text-xs mr-2 py-1.5 px-4 text-gray-800 font-medium bg-green-300 rounded-2xl">
                                    {props?.el?.current_inspection_type}
                                </div>
                            }
                        </div>
                    </div>
                </a>
            </Link>
        </div>
    );
}