/////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////
// Some constants for the App
/////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////

/////////////////////////////////////////////////////////
// Developent
    // export const protocol = "http";
    // export const main_domain = "localhost:4000";
/////////////////////////////////////////////////////////
// Production
export const protocol = "https";
export const main_domain = "imobiliariabraido.com.br";
/////////////////////////////////////////////////////////
// Endpoint
export const api_version = "/api/v2";
export const main_server = `${protocol}://${main_domain}`
// 
export const googleApiKey = process.env.NODE_ENV === "production" ? 'AIzaSyBr5PsIB9IaFw_M7ppKbmWRw4PmXRcykyU' : undefined;
export const openWheaterApiKey = "47db13262c38850ac0c98b339113027d";
