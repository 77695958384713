import ShowRoom from "../components/Inspections/ShowRoom"
import HeaderMetadata from "../components/HeaderMetadata/HeaderMetadata"
export default function App(props) {
    return (
        <>
            <HeaderMetadata
                title="Cômodo"
                description="Cômodo"
            />
            <ShowRoom
                match={props.match}
            />
        </>
    );
}