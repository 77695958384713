import { ToastContainer, } from 'react-toastify';

const setup = {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    newestOnTop: false,
    closeOnClick: true,
    rtl: false,
    pauseOnFocusLoss: true,
    draggable: true,
    pauseOnHover: true,
}

export default function Component() {
    return (
        <ToastContainer {...setup} />
    );
}
