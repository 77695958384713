import React, { useState, useEffect } from "react";

export default function App(props) {
  const [value, setValue] = useState(props.initialValue);

  const onChange = async (e) => {
    e.preventDefault();
    var obj = {};
    setValue(e.target.value);
    obj[e.target.name] = e.target.value;
    props.callback(obj);
    return false;
  };

  useEffect(() => {
    if (props.forceValue) {
      setValue(props.forceValue);
      props.removeForceValue();
    }
  }, [props]);

  return (
    <label className="text-gray-700" for={props.name}>
      <textarea
        name={props.name}
        placeholder={props.placeholder}
        value={value}
        onChange={(e) => onChange(e)}
        rows="5"
        cols="40"
        className={`flex-1
                        appearance-none
                        border-2
                        w-full
                        py-2
                        px-4
                        bg-white
                        text-gray-800
                        font-medium
                        placeholder-gray-400
                        rounded-lg
                        text-base
                        focus:outline-none
                        focus:ring-2
                        focus:border-transparent
                        ${
                          props.type.includes("Saída")
                            ? "border-red-500 focus:ring-red-600"
                            : "border-green-500 focus:ring-green-600"
                        }
                        `}
      />
    </label>
  );
}
