import { useState, useEffect, useRef } from "react"
import { useForm } from "react-hook-form";
import { formSubmitter, avoidSubmit } from "./formSubmitter"
import Input from "./Input"
import Submit from "./Submit"
import { yupResolver } from '@hookform/resolvers/yup';

export default function FormLayout(props) {

    var { endPoint, inputs, yupShema, responseCallback, formCallback, submitLabel, submitColor, additionalData } = props

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(yupShema)
    });

    let [isLoading, setIsLoading] = useState(false);

    const localAction = async (formData, e) => {
        e.preventDefault();
        setIsLoading(true)
        await formSubmitter(formData, endPoint, "Sucesso!", "Erro Inesperado!", responseCallback, additionalData || {})
        setIsLoading(false)
        return false
    }

    return (
        <form onSubmit={isLoading ? (e) => avoidSubmit(e) : handleSubmit(localAction)}>
            <div className="grid grid-cols-2 gap-y-1 gap-x-4">
                {inputs.map((input, index) =>
                    <Input
                        key={index}
                        el={input}
                        register={register}
                        errors={errors}
                        formCallback={formCallback}
                    />
                )}
                <Submit
                    label={submitLabel}
                    loading={isLoading}
                    width={"col-span-2"}
                    mainColor={submitColor}
                    formCallback={formCallback}
                />
            </div>
        </form>
    )
}