export const items = [
      { value: "Porta", label: "Porta" },
      { value: "Portas", label: "Portas" },
      { value: "Portão", label: "Portão" },
      { value: "Portão Social", label: "Portão Social" },
      { value: "Grades", label: "Grades" },
      { value: "Batente", label: "Batente" },
      { value: "Portal", label: "Portal" },
      { value: "Janela", label: "Janela" },
      { value: "Vitrô", label: "Vitrô" },
      { value: "Porta-Janela", label: "Porta-Janela" },
      { value: "Teto", label: "Teto" },
      { value: "Telhado", label: "Telhado" },
      { value: "Cobertura", label: "Cobertura" },
      { value: "Toldo", label: "Toldo" },
      { value: "Paredes", label: "Paredes" },
      { value: "Muro", label: "Muro" },
      { value: "Muros", label: "Muros" },
      { value: "Mureta", label: "Mureta" },
      { value: "Beiral", label: "Beiral" },
    { value: "Corrimão", label: "Corrimão" },
    { value: "Escada", label: "Escada" },
    { value: "Persiana", label: "Persiana" },
    { value: "Cortina", label: "Cortina" },
    { value: "Parapeito", label: "Parapeito" },
      { value: "Piso", label: "Piso" },
      { value: "Calçada", label: "Calçada" },
      { value: "Calçadinha", label: "Calçadinha" },
      { value: "Cimentado", label: "Cimentado" },
      { value: "Elétrica", label: "Elétrica" },
      { value: "Quadro de Distribuição", label: "Quadro de Distribuição" },
      { value: "Interfone", label: "Interfone" },
    { value: "Eletrodomésticos", label: "Eletrodomésticos" },
      { value: "Hidráulica", label: "Hidráulica" },
      { value: "Cavalete", label: "Cavalete" },
      { value: "Armário de Parede", label: "Armário de Parede" },
      { value: "Espelho", label: "Espelho" },
      { value: "Pia", label: "Pia" },
      { value: "Balcão", label: "Balcão" },
      { value: "Gabinete", label: "Gabinete" },
      { value: "Vaso Sanitário", label: "Vaso Sanitário" },
      { value: "Ducha Higiênica", label: "Ducha Higiênica" },
      { value: "Bidê", label: "Bidê" },
      { value: "Box", label: "Box" },
      { value: "Chuveiro", label: "Chuveiro" },
      { value: "Acessórios", label: "Acessórios" },
      { value: "Armário", label: "Armário" },
      { value: "Guarda-Roupa", label: "Guarda-Roupa" },
      { value: "Prateleiras", label: "Prateleiras" },
    { value: "Sofá", label: "Sofá" },
    { value: "Calhas", label: "Calhas" },
    { value: "Rufos", label: "Rufos" },
      { value: "Tanque", label: "Tanque" },
    { value: "Varal", label: "Varal" },
    { value: "Chave", label: "Chave" },
    { value: "Chaves", label: "Chaves" },
    { value: "Chave Tetra", label: "Chave Tetra" },
    { value: "Chave Essencial", label: "Chave Essencial" },
    { value: "Chaves Essenciais", label: "Chaves Essenciais" },
    { value: "Chave Acoplada", label: "Chave Acoplada" },
    { value: "Controle Portão", label: "Controle Portão" },
    { value: "Controle Ar-Condicionado", label: "Controle Ar-Condicionado" },
    { value: "Controle TV", label: "Controle TV" },
    { value: "Plantas", label: "Plantas" },
    { value: "Árvore", label: "Árvore" },
    { value: "Churrasqueira", label: "Churrasqueira" },
    { value: "Piscina", label: "Piscina" },
    { value: "Casa de Gás", label: "Casa de Gás" },
    { value: "Entulhos", label: "Entulhos" },
    { value: "Materiais", label: "Materiais" },
  ];
  
export const rooms = [
    { value: "Desocupação", label: "Desocupação" },
      { value: "Frente", label: "Frente" },
      { value: "Garagem", label: "Garagem" },
      { value: "Área Externa", label: "Área Externa" },
      { value: "Varanda", label: "Varanda" },
      { value: "Corredor Externo", label: "Corredor Externo" },
      { value: "Corredor Externo (direito)", label: "Corredor Externo (direito)" },
      {
        value: "Corredor Externo (esquerdo)",
        label: "Corredor Externo (esquerdo)",
      },
      { value: "Corredor Externo (fundo)", label: "Corredor Externo (fundo)" },
      { value: "Quintal", label: "Quintal" },
      { value: "Quintal dos Fundos", label: "Quintal dos Fundos" },
      { value: "Entrada Social", label: "Entrada Social" },
      { value: "Hall", label: "Hall" },
      { value: "Hall de Entrada", label: "Hall de Entrada" },
      { value: "Sala", label: "Sala" },
      { value: "Salão", label: "Salão" },
      { value: "Sala de Jantar", label: "Sala de Jantar" },
      { value: "Sala de Estar", label: "Sala de Estar" },
      { value: "Sala / Cozinha", label: "Sala / Cozinha" },
      { value: "Sala de TV", label: "Sala de TV" },
      { value: "Lavabo", label: "Lavabo" },
      { value: "Copa", label: "Copa" },
      { value: "Cozinha", label: "Cozinha" },
      { value: "Despensa", label: "Despensa" },
      { value: "Corredor Interno", label: "Corredor Interno" },
      { value: "Escada", label: "Escada" },
      { value: "Dormitório 01", label: "Dormitório 01" },
      { value: "Dormitório 01 (frente)", label: "Dormitório 01 (frente)" },
      { value: "Dormitório 01 (fundos)", label: "Dormitório 01 (fundos)" },
      { value: "Dormitório 01 (meio)", label: "Dormitório 01 (meio)" },
      { value: "Dormitório 01 (suíte)", label: "Dormitório 01 (suíte)" },
      { value: "Dormitório 01 (direito)", label: "Dormitório 01 (direito)" },
      { value: "Dormitório 01 (esquerdo)", label: "Dormitório 01 (esquerdo)" },
      { value: "Dormitório 02", label: "Dormitório 02" },
      { value: "Dormitório 02 (frente)", label: "Dormitório 02 (frente)" },
      { value: "Dormitório 02 (fundos)", label: "Dormitório 02 (fundos)" },
      { value: "Dormitório 02 (meio)", label: "Dormitório 02 (meio)" },
      { value: "Dormitório 02 (suíte)", label: "Dormitório 02 (suíte)" },
      { value: "Dormitório 02 (direito)", label: "Dormitório 02 (direito)" },
      { value: "Dormitório 02 (esquerdo)", label: "Dormitório 02 (esquerdo)" },
      { value: "Dormitório 03", label: "Dormitório 03" },
      { value: "Dormitório 03 (frente)", label: "Dormitório 03 (frente)" },
      { value: "Dormitório 03 (fundos)", label: "Dormitório 03 (fundos)" },
      { value: "Dormitório 03 (meio)", label: "Dormitório 03 (meio)" },
      { value: "Dormitório 03 (suíte)", label: "Dormitório 03 (suíte)" },
      { value: "Dormitório 03 (direito)", label: "Dormitório 03 (direito)" },
      { value: "Dormitório 03 (esquerdo)", label: "Dormitório 03 (esquerdo)" },
      { value: "Closet", label: "Closet" },
      { value: "Sacada", label: "Sacada" },
      { value: "Jardim de Inverno", label: "Jardim de Inverno" },
      { value: "Área de Luz", label: "Área de Luz" },
      { value: "Banheiro Social", label: "Banheiro Social" },
      { value: "Banheiro Social (interno)", label: "Banheiro Social (interno)" },
      { value: "Banheiro Social (externo)", label: "Banheiro Social (externo)" },
      { value: "Banheiro", label: "Banheiro" },
      { value: "Banheiro Suíte 01", label: "Banheiro Suíte 01" },
      { value: "Banheiro Suíte 02", label: "Banheiro Suíte 02" },
      { value: "Banheiro Suíte 03", label: "Banheiro Suíte 03" },
      { value: "Escritório", label: "Escritório" },
      { value: "Lavanderia", label: "Lavanderia" },
      { value: "Cômodo de Despejo", label: "Cômodo de Despejo" },
      { value: "Depósito", label: "Depósito" },
      { value: "Edícula", label: "Edícula" },
      { value: "Cozinha Externa", label: "Cozinha Externa" },
      { value: "Área de Lazer", label: "Área de Lazer" },
      { value: "Área da Churrasqueira", label: "Área da Churrasqueira" },
      { value: "Área da Piscina", label: "Área da Piscina" },
  ];