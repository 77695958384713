import FormLayout from "../../FormLayout";
import * as yup from "yup";
import { ptShort } from 'yup-locale-pt';
yup.setLocale(ptShort);
const yupShema = yup.object();

export default function Comp(props) {
    return (
        <div className="bg-white shadow-lg rounded p-6 w-96" >
            <h1 className="text-2xl font-bold leading-normal bg-white z-20">
                {props.title}
            </h1>
            <p className="text-sm leading-normal bg-white z-20">
                {props.description}
            </p>
            <div className="space-y-5 mt-5">
                <FormLayout
                    endPoint={props?.endPoint}
                    yupShema={yupShema}
                    inputs={[
                        { width: "col-span-2", type: "text", label: props?.loginLabel, name: props?.loginName, },
                        { width: "col-span-2", type: "password", label: "Senha", name: "password", },
                    ]}
                    submitLabel={"Entrar"}
                    submitColor={"blue"}
                    responseCallback={props?.responseCallback}
                    formCallback={props?.formCallback}
                    additionalData={props?.form}
                />
            </div>
            {
                props?.success?.messages &&
                <ul>
                    {props?.success?.messages.map((el, index) =>
                        <li key={index} className="text-green-600 font-semibold bg-green-200 underline px-2 py-2 text-center rounded-full my-4">
                            {el}
                        </li>
                    )}
                </ul>
            }
            {
                props?.error?.error &&
                <ul>
                    {props?.error?.error.map((el, index) =>
                        <li key={index} className="text-red-600 font-semibold bg-red-200 underline px-2 py-2 text-center rounded-full my-4">
                            {el}
                        </li>
                    )}
                </ul>
            }
        </div>
    )
}