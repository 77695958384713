import { useHistory } from "react-router-dom";
import { ArrowLeftIcon } from '@heroicons/react/solid'

export default function App(props) {
    let history = useHistory();
    return (
        <header className={`bg-white shadow top-0 z-20 mb-2 ${props.isSticky ? "sticky" : ""}`}>
            <div className="max-w-7xl mx-auto h-20 justiy-center items-center px-4 sm:px-6 lg:px-8 flex">
                {props.showBackButton &&
                    <button type="button" onClick={() => history.goBack()} className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-white shadow flex-shrink-0 cursor-pointer hover:bg-gray-100 transition ease-in duration-50 focus:bg-gray-100 focus:ring-2 focus:ring-gray-900 focus:ring-offset-2 focus:ring-offset-gray-100">
                        <ArrowLeftIcon className="h-6 w-6" />
                    </button>
                }
                <h1 className="text-3xl font-bold ml-4 text-gray-900">
                    {props.title}
                </h1>
            </div>
        </header>
    );
}