import Dexie from 'dexie'
export const db = new Dexie('DB_V403');

db.version(1).stores({
    inspections: "++local_id, id, image, creator_name, city, full_address_no_city, description, current_inspection_type, start_time, actual_created_at",
    rooms: "++local_id, id, done, room_name, inspection_id, position, actual_created_at, total_inspection_in_figures, total_inspection_out_figures",
    items: "++local_id, id, done, local_room_id, room_id, name, description, status, position, actual_created_at",
    figures: "++local_id, id, position, local_room_id, room_id, local_item_id, item_id, figure_type, filename, mime_type, base64, size, actual_created_at",
    deleatables: "++local_id, room_id, item_id, figure_id, inspection_id, actual_created_at",
    // locations: "++local_id, user_id, lat, lng, accuracy, local_time",
});