import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { XCircleIcon, ClipboardCopyIcon, ArrowCircleRightIcon, CameraIcon, EyeIcon,  ChevronDoubleDownIcon } from '@heroicons/react/solid'
import { deleteLocalRoom, updateRoom, applyDefaultItemsToRoom } from "../../../lib/actions/rooms"
import LocalTextInput from "./LocalTextInput"
import LocalDataSelect from "./LocalDataSelect"
import ToggleDone from "./ToggleDone"
import RoomModelButton from "./ApplyRoomModel"
import {rooms} from "./optionsData"

export default function App(props) {

    const onChange = async (obj) => {
        if (props.el.local_id) {
            await updateRoom(props.el.local_id, obj);
        }
        return false
    }

    return (
        <div className="bg-white shadow-lg rounded-lg w-11/12 mx-auto my-8 p-4">
            <div className="flex w-full justify-between mb-4">
                <div className="w-5/6 flex flex-row justify-start">
                    <div className="w-1/6 flex justify-items-start  content-start">
                        <ToggleDone
                            value={props.el.done}
                            callback={onChange}
                            callback_key_name={"done"}
                        />
                    </div>
                    <div className="w-5/6 flex justify-items-center  content-start">
                        <LocalDataSelect
                            initialValue={props.el.room_name}
                            placeholder="Cômodo"
                            name="room_name"
                            callback={onChange}
                            options={rooms}
                        />
                    </div>
                </div>
                <button type="button" onClick={() => deleteLocalRoom(props.el.local_id)} className="mx-2 my-1 flex justify-start items-start focus:ring-red-500 focus:ring-offset-red-200 transition ease-in duration-200 text-base font-semibold focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg">
                    <XCircleIcon className="h-7 w-7 text-red-600" />
                </button>
            </div>
            <div className="flex w-full justify-between">
                <button type="button" onClick={() => applyDefaultItemsToRoom(props.el.local_id)} className="mx-2 my-1 flex justify-start items-start focus:ring-blue-500 focus:ring-offset-blue-200 transition ease-in duration-200 text-base font-semibold focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg">
                    <ClipboardCopyIcon className="h-7 w-7 text-blue-600" />
                </button>
                <Link to={`${props?.inspection_id}/rooms/${props.el.local_id}/view_figures_in`}>
                    <button type="button" className="inline-flex items-center justify-center px-2 py-1 border border-transparent text-base font-medium rounded-md text-yellow-600 bg-yellow-50">
                        <EyeIcon className="h-6 w-6" />
                    </button>
                </Link>
                <Link to={`${props?.inspection_id}/rooms/${props.el.local_id}/figures_in`}>
                    <button type="button" className="inline-flex items-center justify-center px-2 py-1 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-indigo-50">
                        <CameraIcon className="h-6 w-6"/>
                    </button>
                </Link>
                <Link to={`${props?.inspection_id}/rooms/${props.el.local_id}`}>
                    <button type="button" className="mx-2 my-1 flex justify-start items-start focus:ring-indigo-500 focus:ring-offset-indigo-200 transition ease-in duration-200 text-base font-semibold focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg">
                        <ArrowCircleRightIcon className="h-7 w-7 text-indigo-600" />
                    </button>
                </Link>
            </div>
        </div >
    );
}